import React from 'react';
import styles from './SideMenuItems.module.scss';
import Aux from '../../../hoc/Aux/Aux';
import AdminMenuItems from '../../../constants/AdminMenuItems';
import AddLocalStrings from '../../../constants/AddLocalStrings';
import { getAccountMenuList } from '../../../utils'

const SideMenuItems = (props) => {
    const { appData, articleClickedHandler, articleName, userLevel, localCode } = props;
    let ArticleId = articleName.replace("/admin/", "").replace("/account/", "");
    const { LocalStrings, AccountMenuItems } = appData;
    let AccountMenuList = getAccountMenuList(AccountMenuItems);
    
    return(
    <aside className={styles.SideMenu}>
        { (userLevel === 'GENERAL' || userLevel === 'MASTER') &&
        <Aux>
        <h3 className={styles.SecTile}>{AddLocalStrings[localCode].ADMIN_MENU}</h3>
        <ul>
            <li className={`${(ArticleId ==='/admin') ? styles.MenuActive : null}`} onClick={(e) =>{ articleClickedHandler('admin','/')} }>
                {AddLocalStrings[localCode].ADMIN_HOME}
            </li>
            {AdminMenuItems.map( (menuItem, Index) => {
                const { label, linkName } = menuItem;
                if(linkName === 'ManageAdminGroup' && userLevel !== 'MASTER'){
                    return null;
                } else {
                    return (
                        <li key={label} className={ArticleId === linkName ? styles.MenuActive : null} onClick={(e) =>{ articleClickedHandler('admin', linkName)} }>
                            {AddLocalStrings[localCode][label]}
                        </li>    
                    );
                }
            })}
        </ul>
        </Aux>
        }
        <h3 className={styles.SecTile}>{AddLocalStrings[localCode].ADMIN_INFO_HOME}</h3>
        <ul>
            <li className={`${(ArticleId ==='/account') ? styles.MenuActive : null}`} onClick={(e) =>{ articleClickedHandler('account', "/")} }>
                {AddLocalStrings[localCode].ADMIN_INFO_HOME}
            </li>
            { AccountMenuList.map( menuItem => {
                const {label, linkName} = menuItem;
                let newLabel = label.replace("_LIST", "");
                return(
                <li key={label} className={`${(ArticleId ===linkName) ? styles.MenuActive : null}`} onClick={(e) =>{ articleClickedHandler('account', linkName)} }>
                {LocalStrings[localCode][newLabel]}
                </li>
                )  
            })}
        </ul>
    </aside>
    );
}

export default SideMenuItems;