import {
    UPDATE_CURRENT_HELP_TAB,
    UPDATE_CURRENT_HELP_MODE,
    TOGGLE_HELP_MENU,
    TOGGLE_AUTHWIN_OPEN,
    INIT_AUTH_UI_STATE,
    UI_START_LOADING,
    UI_STOP_LOADING,
    TOGGLE_ERRORWIN_OPEN,
    INIT_ERRORWIN_STATE,
    TOGGLE_ADMIN_MENU,
    SET_ERROR,
    TOGGLE_SUCCESS_OPEN,
    INIT_SUCCESSWIN_STATE
} from './actionTypes'

export const updateHelpTab = (currentTab) =>({
    type: UPDATE_CURRENT_HELP_TAB,
    payload : currentTab
});

export const updateHelpMode = (mode) =>({
    type: UPDATE_CURRENT_HELP_MODE,
    payload : mode
});

export const toggleHelpMenu = (open) =>({
    type: TOGGLE_HELP_MENU,
    payload: open
});

export const toggleAuthWinOpen = () =>({
    type: TOGGLE_AUTHWIN_OPEN
});

export const initAuthUiState = (initState) =>({
    type: INIT_AUTH_UI_STATE,
    payload: initState
});

export const uiStartLoading =(id) =>{
    return {
        type: UI_START_LOADING,
        payload: id
    };
}

export const uiStopLoading =(id) =>{
    return {
        type: UI_STOP_LOADING,
        payload: id
    };
}

export const toggleErrorWinOpen = () =>({
    type: TOGGLE_ERRORWIN_OPEN
});

export const initUiErrorState = (initState) =>({
    type: INIT_ERRORWIN_STATE,
    payload: initState
});

export const toggleAdminMenu = (open) =>({
    type: TOGGLE_ADMIN_MENU,
    payload: open
});

export const setError = (key, error) => {
    return {
        type: SET_ERROR,
        payload: {key, error}
    }
}

export const toggleSuccessWinOpen = () =>({
    type: TOGGLE_SUCCESS_OPEN
});

export const initUiSuccessState = (initState) =>({
    type: INIT_SUCCESSWIN_STATE,
    payload: initState
});