import React from 'react';
import styles from './DropDown.module.scss';
import Spinner from '../Spinner/Spinner';

const DropDown = (props) => {
    const { 
        menuLabel,
        loading, 
        menuLabelStyle,
        containerStyle,
        menuItemStyle, 
        selected, 
        list, 
        visibleItems, 
        height, 
        fontSize, 
        itemRenderer,
        selectedId 
    } = props;

    let itemHeight = height+10;
    let maxHeight = visibleItems * itemHeight;
    let itemStyle = {
        maxHeight: `${maxHeight}px`,
    }
    if(menuItemStyle) {
        itemStyle = {
            ...itemStyle,
            ...menuItemStyle    
        }    
    }
    return(
    <div className={styles.DropDown} style={containerStyle}>
        <div className={menuLabelStyle} style={{width:'100%'}}>
            { loading &&
            <div style={{ position:"absolute"}}>
                <Spinner />
            </div>
            }
            {menuLabel}
        </div>
        <div className={styles.DropDownContent} style={itemStyle}>
          { list.map( (item, index) => {
              return (
              <div 
                key={`${item.id}_${menuLabel}_${index}`} 
                className={styles.DropDownItem} 
                style={{height:itemHeight, fontSize }}
                onClick={() => {selected(item.id)} }
              >{itemRenderer(item, selectedId)}
              </div>)
          })
          }
        </div>
    </div>
    );
};

export default DropDown;