import React from 'react';
import { NavLink } from 'react-router-dom'
import styles from './LogoWithTypeFace.module.css';
import TaguJamTypeface from '../../../assets/images/tagujam_typeface.svg';
import Logo from '../Logo/Logo';
import AddLocalStrings from '../../../constants/AddLocalStrings';

const LogoWithTypeFace = props =>{
    const { localCode } = props;
    return (
    <NavLink to="/"　className={styles.LogoWithTypeFace} exact={true}>
        <Logo width={28} localCode={localCode} />
        <img className={styles.LogoTypeFace} src={TaguJamTypeface} alt={`${AddLocalStrings[localCode].TAGUJAM} ${AddLocalStrings[localCode].TYPE_FACE}`} />
    </NavLink>
    );
}   

export default LogoWithTypeFace;