// import Config from 'react-native-config';

import {
    FETCH_BANK,
    SET_BANK
} from './actionTypes';

import { 
    // uiStartLoading, 
    // uiStopLoading,
    authGetToken,
    // updateTransferStatus
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchBankPending = () => ({
    type: `${FETCH_BANK}_PENDING`
});

export const fetchBankRejected = (err) => ({
    type: `${FETCH_BANK}_REJECTED`,
    payload: err
});

export const fetchBankFulFilled = (bank) => ({
    type: `${FETCH_BANK}_FULFILLED`,
    payload: bank
});

export const fetchBank = (id) => {
    return async dispatch => {
        dispatch(fetchBankPending());
        try{
            let token = await dispatch(authGetToken());
            let bank = await taguJamApi.fetchBank(id, token);
            dispatch(fetchBankFulFilled(bank))
            return bank;
        } catch(err){
            dispatch(fetchBankRejected(err));
            throw err
        }
    }   
};

export const setBank = (bank) => ({
    type: SET_BANK,
    payload: bank
});

// export const saveBank = ( saveData, userId, recipientId) => {
//     return async dispatch => {
//         dispatch(uiStartLoading());
//         try{
//             let token = await dispatch(authGetToken());
//             let newSaveData = {
//                 ...saveData
//             };
//             if(Config.TAGUJAM_ENV!=='prod'){
//                 // hack for test mode
//                 // switch bankInfo to test bank
//                 newSaveData = {
//                     ...newSaveData,
//                     bankInfo: {
//                         ...newSaveData.bankInfo,
//                         accountNumber:'0001234',
//                         bankcode: '1100',
//                         branchcode:'000'
//                     }
//                 } 
//             }
//             let bank = await taguJamApi.saveBank(newSaveData, userId, recipientId, token);
//             dispatch(setBank(bank));
//             dispatch(uiStopLoading());
//             return bank;  
//         } catch(err){
//             dispatch(uiStopLoading());
//             console.log(err);
//             throw err;
//         }
//     }
// }

// export const transferMoneyToBank = ( transferData, sales, providerId, localCode) => {
//     return async dispatch => {
//         dispatch(uiStartLoading());
//         try{
//             let token = await dispatch(authGetToken()); 
//             let salesArray = await taguJamApi.transferToBank(transferData, sales, providerId, token, localCode);
//             dispatch(updateTransferStatus(salesArray));
//             dispatch(uiStopLoading());
//             return salesArray;  
//         } catch(err){
//             dispatch(uiStopLoading());
//             console.log(err);
//             throw err;
//         }
//     }
// }