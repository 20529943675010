import {
    FETCH_USER_INFO,
    SET_USER_INFO,
    SET_EMAIL_CODE,
    SET_VERIFY_STATUS,
    RESET_EMAIL_VERIFICATION
} from './actionTypes';

import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken,
    setEmailVerifyStatus 
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchUserInfoPending = () => ({
    type: `${FETCH_USER_INFO}_PENDING`
});

export const fetchUserInfoRejected = (err) => ({
    type: `${FETCH_USER_INFO}_REJECTED`,
    payload: err
});

export const fetchUserInfoFulFilled = (userInfo) => ({
    type: `${FETCH_USER_INFO}_FULFILLED`,
    payload: userInfo
});

export const fetchUserInfo = (id) => {
    return async dispatch => {
        dispatch(fetchUserInfoPending());
        try{
            let token = await dispatch(authGetToken());
            let userInfo = await taguJamApi.fetchUserInfo(id, token);
            dispatch(fetchUserInfoFulFilled(userInfo))
            return userInfo;
        } catch(err){
            dispatch(fetchUserInfoRejected(err));
            throw err
        }
    }   
};

export const setUserInfo = (userInfo) => ({
    type: SET_USER_INFO,
    payload: userInfo
});

export const saveUserInfo = ( userInfoData, id) => {
    return async dispatch => {
        dispatch(uiStartLoading('saveUserInfo'));
        try{
            let token = await dispatch(authGetToken());
            let userBank = await taguJamApi.fetchUserBankMeta(id, token);
            if(userBank && userBank.recipientId){
                const recipientId = userBank.recipientId;
                const accountData = {
                    userInfo: userInfoData    
                }
                // eslint-disable-next-line no-unused-vars
                const updateUserInfoOfBank = await taguJamApi.saveRecipient( accountData, id, recipientId, token )
            }
            let userInfo = await taguJamApi.saveUserInfo(userInfoData, id, token);
            dispatch(setUserInfo(userInfo));
            dispatch(uiStopLoading('saveUserInfo'));
            return userInfo;  
        } catch(err){
            dispatch(uiStopLoading('saveUserInfo'));
            console.log(err);
            throw err;
        }
    }
}

export const setVerifySatus = (verifyStatus) => ({
    type: SET_VERIFY_STATUS,
    payload: verifyStatus
});

export const saveEmailVerifyStatus = (id, verifyStatus) => {
    return async dispatch => {
        dispatch(uiStartLoading('saveEmailVerifyStatus'));
        try{
            let token = await dispatch(authGetToken()); 
            // eslint-disable-next-line no-unused-vars
            let status = await taguJamApi.saveVerifyStatus(id, verifyStatus, token);
            dispatch(setVerifySatus(verifyStatus));
            if(verifyStatus === "VERIFIED"){
                dispatch(setEmailVerifyStatus(id));    
            }
            dispatch(uiStopLoading('saveEmailVerifyStatus'));
            return verifyStatus;  
        } catch(err){
            dispatch(uiStopLoading('saveEmailVerifyStatus'));
            console.log(err);
            throw err;
        }
    }
}

export const setEmailCode = (emailCode) => ({
    type: SET_EMAIL_CODE,
    payload: emailCode
});

export const sendEmailVerificationCode = (id, email) => {
    return async dispatch => {
        dispatch(uiStartLoading('sendEmailVerificationCode'));
        try{
            let token = await dispatch(authGetToken()); 
            let emailCode = await taguJamApi.sendEmailVerifyCode(id, email, token);
            dispatch(setEmailCode(emailCode));
            dispatch(uiStopLoading('sendEmailVerificationCode'));
            return emailCode;  
        } catch(err){
            dispatch(uiStopLoading('sendEmailVerificationCode'));
            console.log(err);
            throw err;
        }
    }
}

export const resetEmailVerification = () => ({
    type: RESET_EMAIL_VERIFICATION
});