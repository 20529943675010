const AdminMenuItems = [
    {label:'ID_CERT_VERIFY', iconName:'certificate', linkName: 'IdCertVerify'},
    {label:'CUSTOMER_SUPPORT', iconName:'headphones', linkName: 'CustomerSupport'},
    {label:'DEACTIVATE', iconName:'ban', linkName: 'DeActivate'},
    {label:'RENT_ITEM_RESALE', iconName:'money', linkName: 'RentItemResale'},
    {label:'NOTIFICATIONS', iconName:'bell', linkName: 'Notifications'},
    {label:'BLOG_ADMIN', iconName:'file-text', linkName: 'BlogAdmin'},
    {label:'MANAGE_ADMIN_GROUP', iconName:'group', linkName: 'ManageAdminGroup'},
];

export default AdminMenuItems;