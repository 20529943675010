import {
    FETCH_SUPPORT_CASES,
    SET_ASSIGNEE,
    SEND_SUPPORT,
    SET_NEW_MESSAGE
} from './actionTypes';
import {
    authGetToken,
    uiStartLoading, 
    uiStopLoading,
    setError
} from '../actions';

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

const fetchSupportCasesPending = () =>({
    type: `${FETCH_SUPPORT_CASES}_PENDING`
});

const fetchSupportCasesFulFilled = (supportCases) =>({
    type: `${FETCH_SUPPORT_CASES}_FULFILLED`,
    payload: supportCases
});

const fetchSupportCasesRejected = (error) =>({
    type: `${FETCH_SUPPORT_CASES}_REJECTED`,
    payload: error
});

export const fetchSupportCases = () => {
    return async dispatch => {
        dispatch(fetchSupportCasesPending());
        try{
            let token = await dispatch(authGetToken()); 
            const supportCases = await taguJamApi.fetchSupportCases(token);
            dispatch(fetchSupportCasesFulFilled(supportCases));
            return supportCases;
        } catch(err) {
            dispatch(fetchSupportCasesRejected(err));
            console.log(err);
            return err;
        }
    }
}

const setAssignee = (supportId, userId, status) =>({
    type: SET_ASSIGNEE,
    payload: {supportId, userId, status}
});


export const updateAssignee = (supportId, userId, status) => {
    return async dispatch => {
        dispatch(uiStartLoading(`assignee_${supportId}`));
        try{
            let token = await dispatch(authGetToken()); 
            let updateAssigneeResult = await taguJamApi.updateAssignee(supportId, userId, status, token);
            dispatch(setAssignee(supportId, userId, updateAssigneeResult.status));
            dispatch(uiStopLoading(`assignee_${supportId}`));
            return updateAssigneeResult;  
        } catch(err){
            dispatch(uiStopLoading(`assignee_${supportId}`));
            dispatch(setError(`assignee_${supportId}`, err));
            console.log(err);
            throw err;
        }
    }
}

export const sendTheSupportFulFilled = (theSupport) => ({
    type: `${SEND_SUPPORT}_FULFILLED`,
    payload: {...theSupport}
});

const setNewMsg = (newMsg, supportId) => ({
    type: SET_NEW_MESSAGE,
    payload: {newMsg, supportId}
});

export const sendSupport = (supportData, msg, supportId ) => {
    return async dispatch => {
        dispatch(uiStartLoading(`sendMsg_${supportId}`));
        try{
            let token = await dispatch(authGetToken()); 
            let theSupport = await taguJamApi.sendTheSupport(supportData, msg, supportId, token);
            supportData ? dispatch(sendTheSupportFulFilled(theSupport)) 
                        : dispatch(setNewMsg(theSupport, supportId));
            dispatch(uiStopLoading(`sendMsg_${supportId}`));
            return theSupport;
        } catch(err){
            dispatch(uiStopLoading(`sendMsg_${supportId}`));
            dispatch(setError(`sendMsg_${supportId}`, err));
            throw err
        }
    }   
};