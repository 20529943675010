import {
    FETCH_BLOG,
    SET_BLOG,
    UPDATE_CURRENT_BLOG_TAB,
    SET_THE_ARTICLE,
    SET_THE_VIDEO,
    SET_HOW_TO_START,
    SET_EXAMPLES,
    SET_FEATURES,
    SET_BLOG_HOME
} from '../actions/actionTypes';

const initState = {
    blog: null,
    blogIsLoading: false,
    blogError: false,
    currentTab:"articles"
};

const processBlog = (prevBlog, theObject, key) => {
  let newBlog = prevBlog ? {...prevBlog} : {};
  if(key==='blogHome' || key==='howToStart' || key==='examples' || key==='features'){
    newBlog = {
      ...newBlog,
      [key]:theObject 
    } 
  } else {
    const {id} = theObject;
    if(newBlog.hasOwnProperty(key)){
      newBlog = {
        ...newBlog,
        [key]: {
          ...newBlog[key],
          [id]:theObject
        }
      }
    } else {
      newBlog = {
        ...newBlog,
        [key]: {
          [id]:theObject
        }
      }  
    }
  }
  return newBlog;
}

const blogReducer = ( state=initState, action ) => {
  const { type, payload } = action;
  switch( type ) {
    case SET_BLOG:
      return {
        ...state,
        blog: payload
      }
    case `${FETCH_BLOG}_PENDING`:
      return {
        ...state,
        blogIsLoading: true,
        blogError: false,
      }
    case `${FETCH_BLOG}_FULFILLED`:
      return {
        ...state,
        blog: payload,
        blogIsLoading: false,
        blogError: false,
      }
    case `${FETCH_BLOG}_REJECTED`:
      return {
        ...state,
        blog: null,
        blogIsLoading: false,
        blogError: true,
      }
    case UPDATE_CURRENT_BLOG_TAB : 
      return {
        ...state,
        currentTab: payload
      }
    case SET_THE_ARTICLE : 
      return {
        ...state,
        blog: processBlog(state.blog, payload, 'articles'),
      }
    case SET_THE_VIDEO : 
      return {
        ...state,
        blog: processBlog(state.blog, payload, 'videos'),
      }
    case SET_HOW_TO_START:
      return {
        ...state,
        blog: processBlog(state.blog, payload, 'howToStart'),
      }
    case SET_EXAMPLES:
      return {
        ...state,
        blog: processBlog(state.blog, payload, 'examples'),
      }
    case SET_FEATURES:
        return {
          ...state,
          blog: processBlog(state.blog, payload, 'featuers'),
        }
    case SET_BLOG_HOME:
      return {
        ...state,
        blog: processBlog(state.blog, payload, 'blogHome'),
      }
    default:
      return state;
  }
};

export default blogReducer;
