import React, { Component } from 'react';
import styles from './Layout.module.scss';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import SideMenuItems from '../../components/Navigation/SideMenuItems/SideMenuItems';
import Footer from '../../components/Footer/Footer';
import Aux from '../Aux/Aux';
import {connect} from 'react-redux';
import {
    toggleAuthWinOpen,
    initAuthUiState,
    authLogOut,
    toggleAdminMenu
} from '../../store/actions'
import { withRouter } from 'react-router-dom';
import Auth from '../../components/Auth/Auth';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { Toast } from '../../components/UI'
import AddLocalStrings from '../../constants/AddLocalStrings';

class Layout extends Component {

    isHeadless = () =>  {
        const query = new URLSearchParams(this.props.location.search);
        let headless = false;

        for (let param of query.entries()){
            // ['head', 'no']
            if(param[0] === 'head' && param[1] === 'no'){
                headless = true;
            } 
        }
        return headless;
    }

    toggleAuthWinOpenHandler = () => {
        const {onToggleAuthWinOpen} = this.props;
        onToggleAuthWinOpen();
    }

    articleClickedHandler = (section, article) => {
        console.log(article);
        let pathName = `/${section}`;
        if(article !== '/'){
            pathName += `/${article}`;
        }
        this.props.history.push({
            pathname: pathName
        });
        this.sideMenuCloseHandler();
    }

    sideMenuCloseHandler = () => {
        const {onToggleAdminMenu} = this.props;
        const open = false;
        onToggleAdminMenu(open);
    }

    sideMenuToggleHandler = (event) => {
        const {onToggleAdminMenu, showAdminSideMenu} = this.props;
        const open = !showAdminSideMenu;
        onToggleAdminMenu(open);
    }

    render() {
        const {
            uiAuth, 
            onInitAuthUiState,
            onLogout,
            authInfo,
            appData,
            localCode,
            showAdminSideMenu,
            userLevel
        } = this.props;
        const isHeadLess = this.isHeadless();
        if(appData){
            return(
            <Aux>
                { !isHeadLess &&
                <Header 
                    onToggleAuthWinOpen={this.toggleAuthWinOpenHandler}
                    onLogout={onLogout}
                    onInitAuthUiState={onInitAuthUiState}
                    uiAuth={uiAuth}
                    authInfo={authInfo}
                    appData={appData}
                    localCode={localCode}
                />
                }  
                
                <div className={styles.LayoutContainer}>
                    {authInfo && authInfo.token &&  
                    <div className={styles.MobileOnly}>
                        <SideMenu
                            showAdminSideMenu={showAdminSideMenu} 
                            closed={this.sideMenuCloseHandler}
                            articleClickedHandler={this.articleClickedHandler}
                            articleName={this.props.history.location.pathname}
                            userLevel={userLevel}
                            localCode={localCode}
                            appData={appData}
                        />
                        <div className={styles.MenuToggleBtn}
                            onClick={this.sideMenuToggleHandler}>
                            <i className={`fa fa-caret-left ${styles.faiconVertical}`}></i>
                    <div className={styles.SelecterLabelText}>{AddLocalStrings[localCode].ADMIN_ITEMS}</div>
                        </div>
                    </div>
                    }
                    {authInfo && authInfo.token &&  
                    <div className={styles.DeskTopOnly}>
                        <SideMenuItems 
                            articleClickedHandler={this.articleClickedHandler}
                            articleName={this.props.history.location.pathname}
                            userLevel={userLevel}
                            localCode={localCode}
                            appData={appData}
                        />
                    </div>
                    }
                    <div className={styles.LayoutMain}>
                        {this.props.children}
                    </div>
                </div>
                { !isHeadLess &&
                <Footer localCode={localCode} appData={appData} />
                }
                <Auth />
                <ErrorModal />
                <Toast />
            </Aux>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = ({
    local: { localCode },
    appData: {appData, appDataIsLoading, appDataError},
    ui:{ Auth, uiError, Admin:{ showAdminSideMenu } },
    auth:{ authInfo },
    userMeta:{theUserMeta, theUserMetaIsLoading, theUserMetaError},
    userLevel: {userLevel}
}) => ({
    localCode,
    appData, appDataIsLoading, appDataError,
    uiAuth: Auth,
    uiError,
    showAdminSideMenu,
    authInfo,
    theUserMeta, theUserMetaIsLoading, theUserMetaError,
    userLevel  
});

const mapDispatchToProps = dispatch => ({
    onToggleAuthWinOpen : () => dispatch(toggleAuthWinOpen()),
    onInitAuthUiState : (initState) => dispatch(initAuthUiState(initState)),
    onLogout: (userId) => dispatch(authLogOut(userId)),
    onToggleAdminMenu : (open) => dispatch(toggleAdminMenu(open)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));