export const isDataValid = (controls) => {
  let isValid = true
  for (let key in controls){
    if( controls[key].valid === false) {
      isValid = false;
      break;
    }
  }
  return isValid;
}

export const processSelection = (prevArray, newVal) => {
  let pArray = prevArray.length > 0 
    ? [...prevArray] 
    : [];
  if(isSelected(pArray, newVal)){
    pArray = prevArray.filter((item)=>{
      return item !==newVal; 
    });
  } else {
    pArray.push(newVal)
  }
  return pArray;
}

export const isSelected = (list, val) => {
  let selected = false;
  for(let i=0; i < list.length; i++){
    if(list[i] === val){
      selected = true;
      break;
    }
  }
  return selected;
} 