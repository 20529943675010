import {
    FETCH_THE_USER
} from '../actions/actionTypes';

const initState = {
    theUser: null,
    theUserIsLoading: false,
    theUserError: false,
};

const userReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_THE_USER}_PENDING`:{
            return {
                ...state,
                theUserError: false,
                theUserIsLoading: true
            }
        }
        case `${FETCH_THE_USER}_FULFILLED`:{
            return {
                ...state,
                theUserError: false,
                theUserIsLoading: false
            }
        }
        case `${FETCH_THE_USER}_FAILED`:{
            return {
                ...state,
                theUserError: payload,
                theUserIsLoading: false
            }
        }
        default:
            return state;
    }
};

export default userReducer;