import React from 'react';
import TaguJamLogo from '../../../assets/images/tagujam_logo.svg';
import AddLocalStrings from '../../../constants/AddLocalStrings';

const Logo = props => {
    const size = {
        width: props.width ? props.width : '35px',
        height: props.height ? props.height : null,
    }
    const { localCode } = props;
    return(
    <img style={size} src={TaguJamLogo} alt={`${AddLocalStrings[localCode].TAGUJAM} ${AddLocalStrings[localCode].LOGO}`} />
    );
    
} 
export default Logo;
