import {
    FETCH_NO_ITEM_RETURNED_DEALS,
    SET_ASSIGNEE_OF_DEAL,
    SEND_MESSAGE
} from '../actions/actionTypes';

const initState ={
    noItemReturnedDealsIsLoading: false,
    noItemReturnedDealsError: null,
    noItemReturnedDeals:null,
    theMessageIsLoading: false,
    theMessageError: false
};


const messagesReducer = (state=initState, action) => {
    const {type, payload} = action;

    switch(type) {
        case `${FETCH_NO_ITEM_RETURNED_DEALS}_PENDING`: {
            return{
                ...state,
                noItemReturnedDealsIsLoading: true,
                noItemReturnedDealsError: null
            };
        }
        case `${FETCH_NO_ITEM_RETURNED_DEALS}_REJECTED`: {
            return{
                ...state,
                noItemReturnedDealsIsLoading: false,
                noItemReturnedDealsError: payload
            };
        }
        case `${FETCH_NO_ITEM_RETURNED_DEALS}_FULFILLED`: {
            return{
                ...state,
                noItemReturnedDealsIsLoading: false,
                noItemReturnedDeals:payload,
                noItemReturnedDealsError: null
            };
        }
        case SET_ASSIGNEE_OF_DEAL: {
            return{
                ...state,
                noItemReturnedDeals: {
                    ...state.noItemReturnedDeals,
                    [payload.dealId]:{
                        ...state.noItemReturnedDeals[payload.dealId],
                        assignee:payload.userId
                    }
                }
            }
        }
        case `${SEND_MESSAGE}_PENDING`:{
            return {
                ...state,
                theMessageIsLoading: true,
                theMessageError: false
            };
        } 
        case `${SEND_MESSAGE}_FULFILLED`:{
            return {
                ...state,
                theMessageIsLoading: false,
                theMessageError: false,
                noItemReturnedDeals:{
                    ...state.noItemReturnedDeals,
                    [payload.dealId]:payload.message
                }
            };
        }
        case `${SEND_MESSAGE}_REJECTED`:{
            return {
                ...state,
                theMessageIsLoading: false,
                theMessageError:  payload
            };
        }
        default: {
            return state;
        }
    }

}

export default messagesReducer;