import React from 'react';
import styles from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import LogoWithTypeFace from '../Brand/LogoWithTypeFace/LogoWithTypeFace';
import Aux from '../../hoc/Aux/Aux';
import AddLocalStrings from '../../constants/AddLocalStrings';


const openSwitchMode = (event, mode, props) => {
    event.preventDefault();
    const {onToggleAuthWinOpen, onInitAuthUiState} = props;
    const initState = {
        mode: mode, //'SIGN_IN' || 'SIGN_UP',
        closable: true
    }
    onInitAuthUiState(initState);
    onToggleAuthWinOpen();
}

const logOutHandler = (event, props) => {
    event.preventDefault();
    const {onLogout, authInfo: {userId}} = props;
    onLogout(userId);
}

const header = props => {
    const { authInfo: {token}, appData, localCode } = props;
    const { LocalStrings } = appData;
    return (
        <header className={styles.Header}>
            <div className={styles.Brand}><LogoWithTypeFace localCode={localCode} appData={appData} /></div>
            <nav>
                <div className={styles.NavigationItems}>
                    {!token && 
                    <button onClick={(event) => {openSwitchMode(event, 'SIGNIN', props)}} className={styles.Btn}>{LocalStrings[localCode].SIGNIN}</button>
                    }
                    {token && 
                    <Aux>
                    <NavLink to="/account" className={styles.NavigationItem} exact={true}>{AddLocalStrings[localCode].ADMIN_INFO}</NavLink>
                    <button onClick={(event) => {logOutHandler(event, props)}} className={styles.Btn}>{LocalStrings[localCode].LOGOUT}</button>
                    </Aux>
                    }
                </div>   
            </nav>
        </header>
    );
}

export default header;