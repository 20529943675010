import React, {Component} from 'react';
import styles from './Alert.module.scss';
import CloseBtn from '../CloseBtn/CloseBtn';
import Button from '../Button/Button';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../Backdrop/Backdrop';

class Alert extends Component {

    closeWin = () => {
        const {onClose} = this.props;
        onClose();
    }

    render() {
        const {
            closable, 
            errorTitle,
            errorMessage,
            alertOpen,
            onOK,
            okLable,
            onCancel,
            cancelLabel
        } = this.props;
        let attachStyles = [ styles.Alert, styles.Close];

        if(alertOpen){
            attachStyles = [ styles.Alert, styles.Open ];
        }
        return(
        <Aux>
            <Backdrop 
                show={alertOpen}
                clicked={()=>{console.log('Backdrop clicked')}}/>
            <div className={attachStyles.join(' ')}>
                <div className={styles.ErrorTitle}>
                    <h4 style={{display:'flex', flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                        <i className="fa fa-warning" style={{color:'#fff', fontSize:'20px', paddingRight:'10px'}} />
                        {errorTitle}
                    </h4>
                    { closable &&
                    <CloseBtn 
                        clicked={this.closeWin} 
                        fontSize={20} 
                        color="#fff"/>
                    }
                </div>
                <div style={{padding:'15px', display:'flex', flexDirection:"column", alignItems:"center"}}>
                    {errorMessage &&
                    <p>{errorMessage}</p>
                    }
                    <div style={{ width:"80%", display:'flex', flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                        { onOK && 
                        <Button 
                            type="button" 
                            onClick={onOK} 
                            style={{ flex:1, fontSize:'0.75rem', margin:'20px 0 10px 0', padding:'0.5rem'}} >
                            { okLable }
                        </Button>
                        }
                        { onCancel &&
                        <Button 
                            type="button" 
                            onClick={onCancel}
                            BtnType="Secondary" 
                            style={{ flex:1, borderWidth:"1px", fontSize:'0.75rem', margin:'20px 0 10px 10px', padding:'0.5rem'}} >
                            { cancelLabel }
                        </Button>
                        } 
                    </div>
                </div> 
            </div>
        </Aux>
        );   
    }
}

export default Alert;