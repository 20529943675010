import React from 'react';
import styles from './Accordion.module.scss';
import Aux from '../../../hoc/Aux/Aux';

const ClickHiddenHandler = (event) => {
    const  {target} = event;
    let elm = target;
    if(target.classList.contains(styles.CaseBox) === false){
        elm = target.parentElement;
    } 
    elm.classList.toggle(styles.Active);
    let content = elm.nextElementSibling;

    if (content.style.display === "block") {
        content.style.display = "none";
    } else {
        content.style.display = "block";
    }
}

const Accordion = (props) => {
    const { title, content } = props;
    
    return(
    <Aux>
    <div className={styles.CaseBox} onClick={(event) => {ClickHiddenHandler(event)} }>
        {title}
    </div>
    <div className={styles.CaseContent}>
        {content}
    </div>
    </Aux>
    );
    
}

export default Accordion;