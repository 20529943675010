import {
    CHARGE_PAYMENT,
    RESET_CHARGE_ERROR,
    CHARGE_REVERSE
} from '../actions/actionTypes';

const initState = {
    payment: null,
    paymentIsLoading: false,
    paymentError: false,
    paymentIsCharging: false,
    paymentChargeError: false,
    chargeIsReverseing: false,
    chargeReverseError: false
};

const paymentReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${CHARGE_PAYMENT}_PENDING`:{
            return {
                ...state,
                paymentChargeError: false,
                paymentIsCharging: true
            }
        }
        case `${CHARGE_PAYMENT}_FULFILLED`:{
            return {
                ...state,
                paymentChargeError: false,
                paymentIsCharging: false
            }
        }
        case `${CHARGE_PAYMENT}_FAILED`:{
            return {
                ...state,
                paymentChargeError: payload,
                paymentIsCharging: false
            }
        }
        case RESET_CHARGE_ERROR:{
            return {
                ...state,
                paymentChargeError: false,
                paymentIsCharging: false
            }
        }
        case `${CHARGE_REVERSE}_PENDING`:{
            return {
                ...state,
                chargeIsReverseing: true,
                chargeReverseError: false
            }
        }
        case `${CHARGE_REVERSE}_FULFILLED`:{
            return {
                ...state,
                chargeIsReverseing: false,
                chargeReverseError: false
            }
        }
        case `${CHARGE_REVERSE}_FAILED`:{
            return {
                ...state,
                chargeReverseError: payload,
                chargeIsReverseing: false
            }
        }
        default:
            return state;
    }
};

export default paymentReducer;
