import {
    SET_USER_LEVEL
} from './actionTypes';

export const setUserLevel = (userLevel) => ({
    type: SET_USER_LEVEL,
    payload: userLevel
});

// export const fetchAdminLevel = (userId) =>{
//     return async dispatch => {
//         dispatch(fetchAdminLevelPending());
//         try{
//             let token = await dispatch(authGetToken()); 
//             let adminLevel = await taguJamApi.fetchAdminLevel(userId, token);
//             console.log("adminLevel", adminLevel);
//             if(adminLevel===null){
//                 adminLevel = {id:userId};
//             } else {
//                 adminLevel = {
//                     ...adminLevel,
//                     id: userId
//                 }
//             }
//             dispatch(fetchAdminLevelFulFilled(adminLevel))
//             return adminLevel;
//         } catch(err){
//             dispatch(fetchAdminLevelRejected(err));
//             throw err
//         }
//     }   
// }; 