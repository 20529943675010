import React from 'react';
// import { NavLink } from 'react-router-dom';
import styles from './Footer.module.scss';
import AddLocalStrings from '../../constants/AddLocalStrings';

const footer = (props) =>{
    const { localCode } = props;
    const year = new Date().getFullYear();
    return(
        <footer className={styles.Footer}>
            <div className={styles.FooterContent}>
                <div className={styles.Section}>
                    {/* <h5></h5>
                    <p className={styles.Item}>
                        <NavLink to="/admin" exact={true}>
                            {AddLocalStrings[localCode].ADMIN_HOME}
                        </NavLink>
                    </p>
                    */}
                </div>
            </div>
                <p className={styles.CopyRight}>{`©${year} ${AddLocalStrings[localCode].TAGUJAM}　All Rights Reserved.`}</p>
        </footer>
    );
} 

export default footer;