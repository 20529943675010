import {
    SET_THE_SALES,
    FETCH_ALL_SALES,
} from './actionTypes';

import { 
    authGetToken
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const setTheSale = (theSale) => ({
    type: SET_THE_SALES,
    payload: theSale
});

export const saveTheSale = ( chargeData, theOrder, dealId, providerId, customerId, gigId) => {
    return async dispatch => {
        try{
            let token = await dispatch(authGetToken()); 
            let theSale = await taguJamApi.saveTheSale(chargeData, theOrder, dealId, providerId, customerId, gigId, token);
            dispatch(setTheSale(theSale));
            return theSale;  
        } catch(err){
            console.log(err);
            throw err;
        }
    }
}

export const fetchAllSalesPending = () => ({
    type: `${FETCH_ALL_SALES}_PENDING`
});

export const fetchAllSalesRejected = (err) => ({
    type: `${FETCH_ALL_SALES}_REJECTED`,
    payload: err
});

export const fetchAllSalesFulFilled = (allSales) => ({
    type: `${FETCH_ALL_SALES}_FULFILLED`,
    payload: allSales
});

export const fetchAllSales = () => {
    return async dispatch => {
        dispatch(fetchAllSalesPending());
        try{
            let token = await dispatch(authGetToken()); 
            let allSales = await taguJamApi.fetchAllSales(token);
            dispatch(fetchAllSalesFulFilled(allSales))
            return allSales;
        } catch(err){
            dispatch(fetchAllSalesRejected(err));
            throw err
        }
    }   
};

