import {
    SET_NOTIFICATION,
    FETCH_NOTIFICATIONS,
    UPDATE_CURRENT_NOTICE_TAB
} from './actionTypes';
import {
    authGetToken,
    uiStartLoading, 
    uiStopLoading,
    setError
} from '.';

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const setNotification = (notifyData, userId, type) => ({
    type: SET_NOTIFICATION,
    payload: {notifyData, userId, type}
});

export const sendNotification = (msgData, userId, type) => {
    return async dispatch => {
        let supportId = msgData.supportId;
        dispatch(uiStartLoading(`sendNotificationMsg_${supportId}`));
        try{
            let token = await dispatch(authGetToken()); 
            let notifyData = await taguJamApi.sendNotification(msgData, userId, type, token);
            dispatch(setNotification(notifyData, userId, type));
            dispatch(uiStopLoading(`sendNotificationMsg_${supportId}`));
            return notifyData;  
        } catch(err){
            dispatch(uiStopLoading(`sendNotificationMsg_${supportId}`));
            dispatch(setError(`sendNotificationMsg_${supportId}`, err));
            console.log(err);
            throw err;
        }
    }
}

export const fetchNotificationsPending = (type) => ({
    type: `${FETCH_NOTIFICATIONS}_PENDING`,
    payload:{type}
});

export const fetchNotificationsRejected = (err, type) => ({
    type: `${FETCH_NOTIFICATIONS}_REJECTED`,
    payload: {err, type}
});

export const fetchNotificationsFulFilled = (notifications, type) => ({
    type: `${FETCH_NOTIFICATIONS}_FULFILLED`,
    payload: {notifications, type}
});

export const fetchNotifications = (type) => {
    return async dispatch => {
        dispatch(fetchNotificationsPending(type));
        try{
            let token = await dispatch(authGetToken()); 
            let notifications = await taguJamApi.fetchNotifications(type, token);
            dispatch(fetchNotificationsFulFilled(notifications, type));
            return notifications;  
        } catch(err){
            dispatch(fetchNotificationsRejected(err, type));
            console.log(err);
            throw err;
        }
    }    
}
export const updateNoticeTypeTab = (currentTab) =>({
    type: UPDATE_CURRENT_NOTICE_TAB,
    payload : currentTab
});