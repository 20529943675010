import {
    FETCH_THE_USER
} from './actionTypes';
import {
    authGetToken
} from './index';

import TaguJamApi from '../../constants/TaguJamApi';
const taguJamApi = new TaguJamApi();


export const fetchTheUserPending = () => ({
    type: `${FETCH_THE_USER}_PENDING`
});

export const fetchTheUserRejected = (err) => ({
    type: `${FETCH_THE_USER}_REJECTED`,
    payload: err
});

export const fetchTheUserFulFilled = (payment) => ({
    type: `${FETCH_THE_USER}_FULFILLED`,
    payload: payment
});

export const fetchTheUser = (id) => {
    return async dispatch => {
        dispatch(fetchTheUserPending());
        try{
            let token = await dispatch(authGetToken()); 
            let payment = await taguJamApi.fetchTheUser(id, token);
            dispatch(fetchTheUserFulFilled(payment))
            return payment;
        } catch(err){
            dispatch(fetchTheUserRejected(err));
            throw err
        }
    }   
};
