import React, { Fragment, useCallback, useState, useRef, useEffect } from 'react';
import './croppie.css';
import Croppie from "croppie";
import { useDropzone } from 'react-dropzone';
import styles from './ImageDropAndCrop.module.scss';
import {
    extractImageFileExtensionFromBase64,
    downloadBase64File
} from '../../../utils';
import { 
    Button 
} from '../../../components/UI'

const ImageDropAndCrop = (props) => {
    const {
        LocalStrings, 
        localCode,
        fileMax,
        editCancel,
        preview,
        onCropConfirm,
        dropZonImageLabel,
        width,
        height,
        type,
        zoom
    } = props;

    const [imgSrc, setImgSrc] = useState(null);
    const [imgDest, setImgDest] = useState(null);
    const [extn, setExtn] = useState(null);
    const [croppedObj, setCroppedObj] = useState(null);
    const [imgError, setImgError] = useState(null); //FILE_TOO_LARGE, FILE_INVALID_TYPE
    const croppieRef = useRef();

    const handleOnDropAccepted = useCallback((acceptedFiles) => {
        acceptedFiles.map( (file) => {
            console.log("acceptedFile", file);
            return true;
        });
        const currentFile =  acceptedFiles[0];
        const reader = new FileReader();
        reader.addEventListener("load", ()=> {
            const readResult = reader.result;
            const extn = extractImageFileExtensionFromBase64(readResult);
            setImgSrc(readResult);
            setExtn(extn);
        }, false);

        reader.readAsDataURL(currentFile)
        
    }, []);

    const handleOnDropRejected = useCallback((rejectedFiles) => {
        console.log(rejectedFiles);
        if(rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0){
            if(rejectedFiles[0].errors && rejectedFiles[0].errors.length > 0){
                const errorCode = rejectedFiles[0].errors[0].code.toUpperCase().replace(/-/g, "_");
                setImgError(errorCode)
                console.log(errorCode)
            }
        }
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        maxSize: 5 * 1024 * 1024,
        multiple:false,
        accept: ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
        onDropAccepted: handleOnDropAccepted,
        onDropRejected: handleOnDropRejected
    });

    const handleDownLoadClicked = (event) => {
        event.preventDefault();
        const filename = "preview." + extn;
        downloadBase64File(imgDest, filename);     
    }

    const handleCropClicked = (e) => {
        e.preventDefault();
        if(croppedObj){
            croppedObj.result({
                type:'base64',
                format:'jpeg',
                circle:false
            })
            .then( croppedImage64 => {
                console.log(croppedImage64);
                setImgDest(croppedImage64);
                onCropConfirm(croppedImage64);
            });
        }
    }

    const handleCropCancel = (e) => {
        e.preventDefault();
        setImgError(null);
        setImgDest(null);
        setImgSrc(null)
        setExtn(null)
    }

    useEffect(()=> {
        if(croppieRef.current && imgSrc && extn){
            const croppieOptions = {
              showZoomer: true,
              enableOrientation: true,
              enableExif:true,
              viewport: {
                width: width,
                height: height,
                type: type
              },
              boundary: {
                width: width,
                height: height,
              }
            };
            const cropper = new Croppie(croppieRef.current, croppieOptions);
            setCroppedObj(cropper)
            cropper.bind({ url: imgSrc, zoom:zoom});
        }
    }, [croppieRef, imgSrc, extn, width, height, type, zoom]);
    
    return (
      <div style={{width:'100%'}}>
        {imgSrc &&
        <Fragment>
            <div>
                <div ref={croppieRef} />
                <div style={{margin:'-20px 0 10px 0', display:'flex', minWidth:'200px', flexDirection:"row", justifyContent:"space-between"}}>
                    <Button 
                        disabled={false} 
                        loading={false} 
                        type="input"
                        BtnType="Primary" 
                        onClick={handleCropClicked} 
                        style={{ margin:'10px 0 0 0', flex:'1', maxWidth:'49%', fontSize:'0.65em'}} >
                        { LocalStrings[localCode].CONFIRM }
                    </Button>
                    <Button 
                        disabled={false} 
                        loading={false} 
                        type="input"
                        BtnType="Secoundary" 
                        onClick={handleCropCancel} 
                        style={{ margin:'10px 0 0 0', flex:'1', maxWidth:'49%', fontSize:'0.65em'}} >
                        { LocalStrings[localCode].CANCEL }
                    </Button>
                </div>
            </div>
            {imgDest && preview &&
            <Fragment>
                <p>Preview Canvas Crop</p>
                <img 
                    style={{width:'200px', height:'200px'}} 
                    src={imgDest} 
                    alt="preview" /> 
                <button type="button" onClick={handleDownLoadClicked}>Download file</button> 
            </Fragment> 
            }
        </Fragment> 
        }
        {!imgSrc &&
        <div style={{textAlign:'center', marginBottom:'10px'}}>
            <div className={styles.DropZone}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={styles.DropZoneText}>{LocalStrings[localCode].DROP_IMAGE_HERE.replace('{IMAGE_NAME}', dropZonImageLabel)}</p>
                    <i className={`fa fa-user-circle ${styles.DropZonePlaceHolder}`}  />
                    { imgError &&
                    <p className={styles.DropZoneError}>{LocalStrings[localCode][imgError]}</p>
                    }
                    <p style={{marginTop:imgError ? '5px' : '15px'}} className={styles.DropZoneText}>{LocalStrings[localCode].SUPPORTED_FILE_TEXT.replace("{MB}", `${fileMax}`)}</p>
                </div>
            </div>
            <Button 
                disabled={false} 
                loading={false} 
                type="input"
                BtnType="Secoundary" 
                onClick={editCancel} 
                style={{ margin:'10px auto', flex:'1', width:'180px', fontSize:'0.65rem'}} >
                { `${LocalStrings[localCode].EDIT}${LocalStrings[localCode].CANCEL}` }
            </Button>
        </div> 
        }
      </div>
    )
  }

export default ImageDropAndCrop;