import {
    FETCH_ALL_SALES,
    SET_THE_SALES
} from '../actions/actionTypes';

const initState = {
    sales:null,
    salesIsLoading: false,
    salesError: false
};

const salesReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_THE_SALES:
            return {
                ...state,
                sales:{
                    ...state.sales,
                    customer:{
                        ...state.sales.customer,
                        [payload.customer.customerId]:{
                            ...state.sales.customer[payload.customer.customerId],
                            [payload.customer.id]:payload.customer
                        }
                    },
                    provider:{
                        ...state.sales.provider,
                        [payload.provider.providerId]:{
                            ...state.sales.provider[payload.provider.providerId],
                            [payload.provider.id]:payload.provider
                        }
                    }
                }
            }
        case `${FETCH_ALL_SALES}_PENDING`:
            return {
                ...state,
                salesIsLoading: true,
                salesError: false,
            }
        case `${FETCH_ALL_SALES}_FULFILLED`:
            return {
                ...state,
                sales:payload,
                salesIsLoading: false,
                salesError: false,
            }
        case `${FETCH_ALL_SALES}_REJECTED`:
            return {
                ...state,
                sales: null,
                salesIsLoading: false,
                salesError: true,
            }
        default:
            return state;
    }
};

export default salesReducer;