import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './App.scss';
import './fontello.css';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home/Home';
import FirbaseApi from './constants/FirebaseApi';

import { 
  fetchAppData,
  authAutoSignIn,
  setUserLevel
} from './store/actions';

const asyncAccount = asyncComponent(() => {
  return import('./containers/Account/Account');
});

const asyncAdmin = asyncComponent(() => {
  return import('./containers/Admin/Admin');
});

class App extends Component {

  componentDidMount() {
      const {onFetchAppData, onAutoSignIn, onSetUserLevel} = this.props;

      FirbaseApi.app.auth().onAuthStateChanged( user => {
        if(user){
            FirbaseApi.getUserLevel()
            .then(userLevel => {
                onSetUserLevel(userLevel);
            })
            .catch(error => {
                //console.log("error", error)
            });
        } else {
            onSetUserLevel("NONE");
        }
      });
      onFetchAppData()
      .then( appData => {
          onAutoSignIn()
          .then(tokenObj => {
            console.log("auto Sign In sucess!");
            this.props.history.push("/admin");
          })
          .catch(error => {
          });
      })
      .catch(error => {
      });
  }

  render(){
    const {authInfo:{token}} = this.props;
    let routes = <Switch>
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>;

    if(token){
      routes = <Switch>
        <Route path="/admin" component={asyncAdmin} />
        <Route path="/account" component={asyncAccount} />
        <Route path="/" exact component={Home} />
      </Switch>;
    }  

    return (
      <div className="App">
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = ({ 
  appData: { appData, appDataIsLoading, appDataError },
  auth:{ authInfo }
}) => ({
  appData, appDataIsLoading, appDataError,
  authInfo
});

const mapDispatchToProps = dispatch => ({
  onFetchAppData : () => dispatch( fetchAppData() ),
  onAutoSignIn : () => dispatch(authAutoSignIn()),
  onSetUserLevel : (userLevel) => dispatch(setUserLevel(userLevel))
}); 

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));