import React, {Component} from 'react';
import styles from './Auth.module.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LogoWithTypeFace from '../Brand/LogoWithTypeFace/LogoWithTypeFace';
import Aux from '../../hoc/Aux/Aux';
import {
    Backdrop,
    CloseBtn,
    FormInput,
    Button,
    TextLink,
    CheckBox
} from '../UI';
import {
    toggleAuthWinOpen,
    initAuthUiState,
    tryAuth,
    sendResetPasswordMsg,
    setAuthError,
    setAuthSuccess 
} from '../../store/actions';
import IpfyApi from '../../constants/IpifyApi';
import Validate from '../../utils/Validation';
import AddLocalStrings from '../../constants/AddLocalStrings';
const ipfyApi = new IpfyApi();

class Auth extends Component {

    state = {
        controls: {
            email: {
                value: "",
                valid: false,
                validationRules: {
                    isEmail: true
                },
                errorMsg:null,
                touched:false
            },
            password: {
                value: "",
                valid: false,
                validationRules: {
                    minLength: 6
                },
                errorMsg:null,
                touched:false
            },
            passwordConfirm : {
                value: "",
                valid: false,
                validationRules: {
                    equalTo: 'password'
                },
                errorMsg:null,
                touched:false
            },
        },
        termsAgreed:false,
        checkBoxError:null,
        accountDeactivatedWin:false
    }

    onChangeInput = (event, key) => {
        const {onSetAuthError, authError} = this.props;
        const {target:{value}} = event;

        this.setState(prevState => {
            let equalVal = "";
            if (prevState.controls[key].validationRules.equalTo){
                const equalKey = prevState.controls[key].validationRules.equalTo;
                equalVal = prevState.controls[equalKey].value;
            }
            let passwordConfirmResult = null;
            let passwordConfirmError;
            if(key === 'password') {
                passwordConfirmResult = Validate(
                    prevState.controls.passwordConfirm.value, 
                    prevState.controls.passwordConfirm.validationRules, 
                    value
                ); 
                passwordConfirmError = passwordConfirmResult.error.length > 0 ?  passwordConfirmResult.error[0] : ""
            }
            const result = Validate(
                value, 
                prevState.controls[key].validationRules, 
                equalVal
            );
            return {
                ...prevState,
                controls:{
                    ...prevState.controls,
                    passwordConfirm: {
                        ...prevState.controls.passwordConfirm,
                        valid: key === 'password'
                        ? passwordConfirmResult.isValid
                        : prevState.controls.passwordConfirm.valid,
                        errorMsg: key === 'password'
                        ? passwordConfirmError
                        : prevState.controls.passwordConfirm.errorMsg,   
                    },   
                    [key]: {
                        ...prevState.controls[key],
                        value: value,
                        touched: true,
                        valid: result.isValid,
                        errorMsg: result.error.length > 0 ?  result.error[0] : null 
                    }
                }
            }
        },
        () => {
            if(authError){
                onSetAuthError(null);
            } 
        }
        );
    }

    checkTermsAgreed = (event) => {
        const { localCode } = this.props;
        this.setState(prevState => {
            let val = !prevState.termsAgreed
            return{
                ...prevState,
                termsAgreed:val,
                checkBoxError:!val ? AddLocalStrings[localCode].PLEASE_AGREE : null
            }
        })
    }

    openSwitchMode = (event, newMode) => {
        event.preventDefault();
        const {onInitAuthUiState} = this.props;
        const initState = {
            mode: newMode
        }
        onInitAuthUiState(initState);
    }

    onSendResetMsg = () => {
        const { onSendResetPasswordMsg, onSetAuthError, onSetAuthSuccess, onInitAuthUiState, localCode } = this.props;
        const { controls } = this.state;
        try {
            const email = controls.email.value;
            onSendResetPasswordMsg(email, localCode)
            .then( result => {
                const initState = {
                    mode: "SIGNIN", //Change back to sign in for session Time out,
                };
                onSetAuthSuccess("SEND_PASSWORD_RESET_EMAIL_SUCCESS")
                onInitAuthUiState(initState);
            })
            .catch(err => {
                let errorMessage;
                console.log(err);
                switch( err.message ) {
                    case "EMAIL_NOT_FOUND":{
                        errorMessage = "EMAIL_NOT_FOUND";
                        break;
                    }
                    default:{
                        errorMessage = "SEND_PASSWORD_RESET_EMAIL_ERROR";
                    }
                }
                onSetAuthError(errorMessage);
            });
        } catch (err) {
            console.log(err);
            onSetAuthError(err.message);
        }    
    }

    onSubmit = async (event) => {
        event.preventDefault();
        const { onSetAuthError, onTryAuth, onSetAuthSuccess, onToggleAuthWinOpen, onInitAuthUiState, uiAuth } = this.props;
        const { mode } = uiAuth;
        const { controls } = this.state;
        const today = Math.round(new Date().getTime()/1000);
        onSetAuthSuccess(null);
        try {
            const ipAddress = await ipfyApi.getIpAddress();
            const authData = {
                email: controls.email.value,
                password: controls.password.value,
                platform: "web",
                ipAddress: ipAddress,
                today: today
            }
            onTryAuth(authData, mode)
            .then( tokenObj => {
                onToggleAuthWinOpen();
                if(mode === 'SIGNUP'){
                    const initState = {
                        mode: "SIGNIN", //Change back to sign in for session Time out,
                    };
                    onInitAuthUiState(initState);
                }
                this.props.history.push("/admin");
            })
            .catch(err => {
                console.log(err);
                onSetAuthError(err.message);
            });
        } catch (e) {
            console.log(e);
        }
    }

    getServerErrorStr = (errorMsg) => {
        const {localCode, appData:{LocalStrings}, uiAuth} = this.props;
        const { mode } = uiAuth;
        switch(errorMsg){
            case "INVALID_PASSWORD": {
                return LocalStrings[localCode].PASSWORD_EMAIL_ERROR;
            }  
            case "EMAIL_NOT_FOUND":{
                return LocalStrings[localCode].PASSWORD_EMAIL_ERROR;
            }
            case "EMAIL_EXISTS":{
                return LocalStrings[localCode].EMAIL_EXISTS; 
            }
            case "SESSION_TIME_OUT":{
                return LocalStrings[localCode].SESSION_TIME_OUT;
            }
            default:{
                return `${LocalStrings[localCode][mode]}${LocalStrings[localCode].AUTH_ERROR}`;
            }
        }
    }

    getErrorStr = (errorMsg) => {
        const {appData, localCode} = this.props;
        const { controls } = this.state;
        const { LocalStrings } = appData;

        switch(errorMsg){
            case 'INVALID_LENGTH': {
                return `${controls.password.validationRules.minLength}${LocalStrings[localCode][controls.password.errorMsg]}`;
            }
            case null: {
                return null;
            }
            default: {
                return LocalStrings[localCode][errorMsg];
            }
        }
    }

    isDataValid = () => {
        const { termsAgreed, controls} = this.state;
        const { uiAuth } = this.props;
        const { mode } = uiAuth;
        let isValid = true
        for (let key in controls){
            if(key === 'passwordConfirm'){
                if(mode==='SIGNUP' && controls[key].valid===false){
                    isValid = false;
                    break;    
                }
            } else if(key === 'password'){
                if( (mode==='SIGNUP'||mode==='SIGNIN') && controls[key].valid===false){
                    isValid = false;
                    break;    
                } 
            } else {
                if(controls[key].valid===false){
                    isValid = false;
                    break;
                }
            }
        }
        if(mode==='SIGNUP' && !termsAgreed){
            isValid=false;
        }
        return isValid;
    }


    buttonDisable = () => {
        const { authIsLoading } = this.props;
        return ( authIsLoading || this.isDataValid()===false);   
    }

    coloseButtonHandler = () => {
        const {onSetAuthError, onToggleAuthWinOpen, authError} = this.props;
        if(authError){
            onSetAuthError(null);
        }
        onToggleAuthWinOpen();
    }

    render() {
        const {termsAgreed, checkBoxError, controls} = this.state;
        const {
            localCode, 
            appData, appDataIsLoading, appDataError, 
            uiAuth,
            authError, authSuccess
        } = this.props;
        
        const { authWinOpen, mode, closable } = uiAuth;
        let attachStyles = [ styles.Auth, styles.Close];

        if(authWinOpen){
            attachStyles = [ styles.Auth, styles.Open ];
        }

        if(appDataIsLoading){
            return(<p>loading...</p>);
        } else if(appDataError){
            return(<p>error</p>);
        } else {
            if(appData){
                const { LocalStrings } = appData;
                const submitBtnLabel = (mode ==="SIGNIN" || mode ==="SIGNUP") ? LocalStrings[localCode][mode] : LocalStrings[localCode].SEND_PASSWORD_RESET_EMAIL;
                const submitFunc = (mode ==="SIGNIN" || mode ==="SIGNUP") ? this.onSubmit : this.onSendResetMsg;
                return(
                <Aux>
                    <Backdrop 
                        show={authWinOpen}
                        clicked={()=>{console.log('Backdrop clicked')}}/>
                    <div className={attachStyles.join(' ')}>
                        { closable &&
                        <div style={{textAlign:"right"}}>
                            <CloseBtn 
                                clicked={this.coloseButtonHandler} 
                                fontSize={20} 
                                color="gray"/>
                        </div>
                        }
                        <div className={styles.Brand}>
                            <LogoWithTypeFace localCode={localCode} appData={appData} />
                        </div>
                        {authError && 
                        <div className={styles.ErrorBox}>
                            <p>{this.getServerErrorStr(authError)}</p>
                        </div>
                        }
                        {authSuccess && 
                        <div className={styles.SuccesBox}>
                            <p>{LocalStrings[localCode][authSuccess]}</p>
                        </div>
                        }
                        <form style={{maxWidth:'80%', textAlign:"left", margin:'15px auto 15px auto'}}>
                            <FormInput 
                                label={LocalStrings[localCode].EMAIL} 
                                placeholder={LocalStrings[localCode].EMAIL_INPUT}
                                customStyle={{minHeight: "80px"}}
                                customInputStyle={{minHeight: "40px"}} 
                                type="text"
                                value={controls.email.value}
                                touched={controls.email.touched}
                                error={this.getErrorStr(controls.email.errorMsg)}
                                valid={controls.email.valid} 
                                onChange={event=> this.onChangeInput(event, "email")}
                                onClear={() => { this.onChangeInput( {target:{value:null}}, "email" )} } />
                            {(mode === "SIGNUP" || mode === "SIGNIN") &&
                            <FormInput 
                                label={LocalStrings[localCode].PASSWORD} 
                                placeholder={LocalStrings[localCode].PASSWORD_INPUT}
                                customStyle={{minHeight: "80px"}}
                                customInputStyle={{minHeight: "40px"}}   
                                type="password" 
                                value={controls.password.value}
                                touched={controls.password.touched}
                                valid={controls.password.valid}
                                error={this.getErrorStr(controls.password.errorMsg)} 
                                onChange={event=> this.onChangeInput(event, "password")} 
                                onClear={() => { this.onChangeInput( {target:{value:null}}, "password" )} } />
                            }
                            {mode==='SIGNUP' && 
                            <FormInput 
                                label={LocalStrings[localCode].CONFIRM_PASSWORD} 
                                placeholder={LocalStrings[localCode].CONFIRM_PASSWORD_INPUT}
                                customStyle={{minHeight: "80px"}} 
                                customInputStyle={{minHeight: "40px"}}  
                                type="password" 
                                value={controls.passwordConfirm.value}
                                touched={controls.passwordConfirm.touched}
                                valid={controls.passwordConfirm.valid}
                                error={this.getErrorStr(controls.passwordConfirm.errorMsg)} 
                                onChange={event=> this.onChangeInput(event, "passwordConfirm")} 
                                onClear={() => { this.onChangeInput( {target:{value:null}}, "passwordConfirm" )} } />
                            }
                            {mode==='SIGNUP' &&
                            <div style={{ display:'flex', marginTop:'5px', flexDirection:'row', justifyContent:'space-between'}}>
                                <div style={{flex:1}}>
                                <CheckBox 
                                    label={LocalStrings[localCode].TERMS_AGREEMENT_TEXT}
                                    changed={this.checkTermsAgreed}
                                    checked={termsAgreed}
                                    error={checkBoxError} 
                                    />
                                </div>
                                <div style={{flex:1, paddingLeft:10}}>
                                <TextLink
                                    href="/terms" 
                                    target="blank"
                                    style={{ display:'block', margin:0, textAlign:"left", fontSize: '0.75rem', fontWeight: 'bold'}} >
                                    { `${LocalStrings[localCode].READ_TERMS}` }
                                </TextLink>
                                <TextLink
                                    href="/privacy" 
                                    target="blank"
                                    style={{ display:'block', margin:0, textAlign:"left", fontSize: '0.75rem', fontWeight: 'bold'}} >
                                    { `${LocalStrings[localCode].READ_PRIVACY_POLICY}` }
                                </TextLink>
                                </div>
                            </div>
                            }
                            <Button 
                                disabled={this.buttonDisable()} 
                                loading={this.props.authIsLoading} 
                                type="input" 
                                onClick={submitFunc} 
                                style={{ margin:'10px 0 0 0'}} >
                                { submitBtnLabel }
                            </Button>
                            {(mode ==="SIGNIN" || mode ==="SIGNUP") && 
                            <TextLink
                                href="#" 
                                onClick={(event)=>{const newMode = mode ==="SIGNIN"? "SIGNUP" : "SIGNIN"; this.openSwitchMode(event, newMode)}} 
                                style={{ display:'block', margin:'15px auto 15px auto', fontSize: '0.95em', fontWeight: 'bold'}} >
                                { mode==='SIGNIN' 
                                    ? `${LocalStrings[localCode].NOT_HAVE_ACCOUNT}` 
                                    : `${LocalStrings[localCode].ALREADY_HAVE_ACCOUNT}`}
                            </TextLink>
                            }
                            {mode ==="FORGET_PASSWORD" && 
                            <TextLink
                                href="#" 
                                onClick={(event)=>{this.openSwitchMode(event, "SIGNIN")}} 
                                style={{ display:'block', margin:'15px auto 30px auto', fontSize: '0.95em', fontWeight: 'bold'}} >
                                { LocalStrings[localCode].BACK_TO_SIGNIN　}
                            </TextLink>
                            }
                            {mode ==="SIGNIN" && 
                            <TextLink
                                href="#" 
                                onClick={(event)=>{this.openSwitchMode(event, "FORGET_PASSWORD")}} 
                                style={{ display:'block', margin:'0px auto 30px auto', fontSize: '0.95em', fontWeight: 'bold'}} >
                                {LocalStrings[localCode].FORGET_PASSWORD}
                            </TextLink>
                            }
                        </form>
                    </div>
                </Aux>
                );
            } else {
                return null;
            }
        }      
    }

}

const mapStateToProps = ({
    ui:{ Auth },
    local: { localCode },
    appData: {appData, appDataIsLoading, appDataError},
    auth:{authIsLoading, authError, authSuccess}
}) => ({
    uiAuth: Auth,
    localCode,
    appData, appDataIsLoading, appDataError,
    authIsLoading, authError, authSuccess  
});

const mapDispatchToProps = dispatch => ({
    onToggleAuthWinOpen : () => dispatch(toggleAuthWinOpen()),
    onInitAuthUiState : (initState) => dispatch(initAuthUiState(initState)),
    onTryAuth: (authData, mode) => dispatch( tryAuth(authData, mode) ),
    onSendResetPasswordMsg: (email, localCode) => dispatch( sendResetPasswordMsg(email, localCode) ),
    onSetAuthError: (errorMsg) => dispatch(setAuthError(errorMsg)),
    onSetAuthSuccess: (successMsg) => dispatch(setAuthSuccess(successMsg))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth));