import {
    FETCH_GIGS,
    UPDATE_THE_GIG
} from './actionTypes';


import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchGigs = () => ({
    type: FETCH_GIGS,
    payload: taguJamApi.fetchGigs()    
});

export const updateTheGig = (gigId, updateData) => ({
    type: UPDATE_THE_GIG,
    payload: {gigId, updateData}
});
