import React from 'react';
import styles from './CreditCard.module.scss';
import VISA from '../../../assets/images/creditCards/VISA.svg';
import MASTER from '../../../assets/images/creditCards/MASTER.svg';
import DINERS from '../../../assets/images/creditCards/DINERS.svg';
import AMEX from '../../../assets/images/creditCards/AMEX.svg';
import JCB from '../../../assets/images/creditCards/JCB.svg';

const CreditCard = (props) => {
    const { cardType, width } = props;
    let customStyle = null;
    if(width){
        customStyle= {
            width: width,
            height: width * 0.632
        }    
    }
    switch(cardType){
        case 'VISA': {
            return <img className={styles.CreditCard} style={customStyle} src={VISA} alt={cardType}  />
        }
        case 'MASTER': {
            return <img className={styles.CreditCard} style={customStyle} src={MASTER} alt={cardType}  />
        }
        case 'AMEX': {
            return <img className={styles.CreditCard} style={customStyle} src={AMEX} alt={cardType}  />
        }
        case 'DINERS': {
            return <img className={styles.CreditCard} style={customStyle} src={DINERS} alt={cardType}  />
        }
        case 'JCB': {
            return <img className={styles.CreditCard} style={customStyle} src={JCB} alt={cardType}  />
        }
        default:{
            return null;
        }
    }
};

export default CreditCard;