import {
    FETCH_ID_CERT_VERIFICATION,
    UPDATE_CURRENT_ID_CERT_TAB,
    UPDATE_VERIFICATION_STATUS
} from './actionTypes';
import {
    authGetToken
} from '../actions'
import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

const fetchIdCertVerificaitonPending = () =>({
    type: `${FETCH_ID_CERT_VERIFICATION}_PENDING`
});

const fetchIdCertVerificaitonFulFilled = (certType, verifyList) =>({
    type: `${FETCH_ID_CERT_VERIFICATION}_FULFILLED`,
    payload: {certType, verifyList}
});

const fetchIdCertVerificaitonRejected = (error) =>({
    type: `${FETCH_ID_CERT_VERIFICATION}_REJECTED`,
    payload: error
});

export const fetchIdCertVerificaiton = (certType) => {
    return async dispatch => {
        dispatch(fetchIdCertVerificaitonPending());
        try{
            let token = await dispatch(authGetToken()); 
            const verifyList = await taguJamApi.fetchIdCertVerificaiton(certType, token);
            dispatch(fetchIdCertVerificaitonFulFilled(certType, verifyList));
            return verifyList;
        } catch(err) {
            dispatch(fetchIdCertVerificaitonRejected(err));
            console.log(err);
            return err;
        }
    }
}

const updateVerificationStatusPending = (certType, userId) =>({
    type: `${UPDATE_VERIFICATION_STATUS}_PENDING`,
    payload: {certType, userId}
});

const updateVerificationStatusFulFilled = (verifyData, certType, userId) =>({
    type: `${UPDATE_VERIFICATION_STATUS}_FULFILLED`,
    payload: {verifyData, certType, userId}
});

const updateVerificationStatusRejected = (error, certType, userId) =>({
    type: `${UPDATE_VERIFICATION_STATUS}_REJECTED`,
    payload: {error, certType, userId}
});

export const updateVerificationStatus = (verifyData, certType, userId) => {
    return async dispatch => {
        dispatch(updateVerificationStatusPending(certType, userId));
        try{
            let token = await dispatch(authGetToken()); 
            // eslint-disable-next-line no-unused-vars
            const verifyStatus = await taguJamApi.updateVerificationStatus(verifyData, certType, userId, token);
            dispatch(updateVerificationStatusFulFilled(verifyData, certType, userId));
            return verifyData;
        } catch(err) {
            dispatch(updateVerificationStatusRejected(err, certType, userId));
            console.log(err);
            return err;
        }
    }
}

export const updateIdCertTab = (currentTab) =>({
    type: UPDATE_CURRENT_ID_CERT_TAB,
    payload : currentTab
});
    
