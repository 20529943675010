import React from 'react';
import styles from './AvatorWithPR.module.scss';
import Avator from '../Avator/Avator';
import AddLocalStrings from '../../../constants/AddLocalStrings';

const AvatorWithPR = (props) => {
    const {localCode, accountInfo} = props;
    return(
    <div className={styles.RowDisplay}>
        <div className={styles.AvatorBox}>
            <Avator avator={accountInfo.avator} size={16} alt={`${accountInfo.diplayName} ${AddLocalStrings[localCode].PHOTO}`} />
            <p className={styles.DisplayName}>{accountInfo.displayName}</p>
        </div>
        <p>{accountInfo.selfPR}</p>
    </div>
    );
}

export default AvatorWithPR;