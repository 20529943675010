import {
    SET_DEACTIVATE,
    FETCH_DEACTIVATIONS,
    SET_THE_DEACTIVATE
} from './actionTypes';
import {
    authGetToken,
    uiStartLoading, 
    uiStopLoading,
    setError,
    updateTheUserMeta,
    updateTheGig,
    sendNotification   
} from './index';
import TaguJamApi from '../../constants/TaguJamApi';
import AddLocalStrings from '../../constants/AddLocalStrings';

const taguJamApi = new TaguJamApi();

const fetchDeactivationsPending = () =>({
    type: `${FETCH_DEACTIVATIONS}_PENDING`
});

const fetchDeactivationsFulFilled = (deactivations) =>({
    type: `${FETCH_DEACTIVATIONS}_FULFILLED`,
    payload: deactivations
});

const fetchDeactivationsRejected = (error) =>({
    type: `${FETCH_DEACTIVATIONS}_REJECTED`,
    payload: error
});

export const fetchDeactivations = () => {
    return async dispatch => {
        dispatch(fetchDeactivationsPending());
        try{
            let token = await dispatch(authGetToken()); 
            const deactivations = await taguJamApi.fetchDeactivations(token);
            dispatch(fetchDeactivationsFulFilled(deactivations));
            return deactivations;
        } catch(err) {
            dispatch(fetchDeactivationsRejected(err));
            console.log(err);
            return err;
        }
    }
}

export const setDeactivate = (requestResult) => ({
    type: SET_DEACTIVATE,
    payload: requestResult
});

export const sendDeactivateReq = (requestData, supportId) => {
    return async dispatch => {
        dispatch(uiStartLoading(`requestDeactivate_${supportId}`));
        try{
            let token = await dispatch(authGetToken()); 
            let requestResult = await taguJamApi.sendDeactivateReq(requestData, token);
            dispatch(uiStopLoading(`requestDeactivate_${supportId}`));
            dispatch(setDeactivate(requestResult));
            return requestResult;  
        } catch(err){
            dispatch(uiStopLoading(`requestDeactivate_${supportId}`));
            dispatch(setError(`requestDeactivate_${supportId}`, err));
            console.log(err);
            throw err;
        }
    }    
}

export const setTheDeactivate = (updateDeactive, id) => ({
    type: SET_THE_DEACTIVATE,
    payload: {updateDeactive, id }
});

export const deactivateObjectUpdate = (deactivateId, objectId, type, value, noticeUserId, supportId, assignee, localCode ) => {
    return async dispatch => {
        dispatch(uiStartLoading(`deactivateObj_${deactivateId}`));
        try{
            let token = await dispatch(authGetToken());
            let updateFunc = type === 'gig' ? updateTheGig : updateTheUserMeta; 
            let updateDeactive = await taguJamApi.deactivateObject(deactivateId, objectId, type, value, token);
            let noticeTypeStr = type === 'gig' ? 'DEACTIVATE_SERVICE' : 'DEACTIVATE_ACCOUNT';
            if(!value){
                noticeTypeStr += '_REVERT';    
            }
            let msgData = {
                msg: AddLocalStrings[localCode][`${noticeTypeStr}_TEXT`],
                read: false,
                title: AddLocalStrings[localCode][noticeTypeStr],
                type: noticeTypeStr,
                noticeMsgCode: noticeTypeStr,
                supportId,
                assignee
            };
            // eslint-disable-next-line no-unused-vars
            let notice = await dispatch(sendNotification(msgData, noticeUserId, 'user'));
            dispatch(uiStopLoading(`deactivateObj_${deactivateId}`));
            let updateData = { deactivated_at: updateDeactive.deactivated_at, active:!value }
            dispatch(updateFunc(objectId, updateData));
            dispatch(setTheDeactivate(updateDeactive, deactivateId));
            return updateDeactive;  
        } catch(err){
            dispatch(uiStopLoading(`deactivateObj_${deactivateId}`));
            dispatch(setError(`deactivateObj_${deactivateId}`, err));
            console.log(err);
            throw err;
        }
    }    
}