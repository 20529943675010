import {
    FETCH_ID_CERT_VERIFICATION,
    UPDATE_CURRENT_ID_CERT_TAB,
    UPDATE_VERIFICATION_STATUS    
} from '../actions/actionTypes';

const initState = {
    idCertVerification:null,
    idCertVerificationPending:false,
    idCertVerificationError:null,
    idCertVerifyStatusUpdating:{},
    idCertVerifyStatusError:{},
    currentTab:"IDENTIFIED"
}

const idCertVerificationReducer = (state=initState, action) => {
    const {type, payload} = action;
    switch(type){
        case `${FETCH_ID_CERT_VERIFICATION}_PENDING` : {
            return{
                ...state,
                idCertVerificationPending:false,
                idCertVerificationError:null
            }
        }
        case `${FETCH_ID_CERT_VERIFICATION}_REJECTED` : {
            return {
                ...state,
                idCertVerificationPending:false,
                idCertVerificationError:payload
            }
        }
        case `${FETCH_ID_CERT_VERIFICATION}_FULFILLED` : {
            return {
                ...state,
                idCertVerificationPending:false,
                idCertVerification:{
                    ...state.idCertVerification,
                    [payload.certType]:payload.verifyList   
                } 
            }
        }
        case `${UPDATE_VERIFICATION_STATUS}_PENDING` : {
            return{
                ...state,
                idCertVerifyStatusUpdating: {
                    ...state.idCertVerifyStatusUpdating,
                    [payload.certType] : {
                        ...state.idCertVerifyStatusUpdating[payload.certType],
                        [payload.userId]: true
                    }
                },
                idCertVerifyStatusError:{
                    ...state.idCertVerifyStatusError,
                    [payload.certType] : {
                        ...state.idCertVerifyStatusError[payload.certType],
                        [payload.userId]: null
                    }
                }
            }
        }
        case `${UPDATE_VERIFICATION_STATUS}_REJECTED` : {
            return{
                ...state,
                idCertVerifyStatusUpdating: {
                    ...state.idCertVerifyStatusUpdating,
                    [payload.certType] : {
                        ...state.idCertVerifyStatusUpdating[payload.certType],
                        [payload.userId]: false
                    }
                },
                idCertVerifyStatusError:{
                    ...state.idCertVerifyStatusError,
                    [payload.certType] : {
                        ...state.idCertVerifyStatusError[payload.certType],
                        [payload.userId]: payload.error
                    }
                }
            }
        }
        case `${UPDATE_VERIFICATION_STATUS}_FULFILLED` : {
            return{
                ...state,
                idCertVerifyStatusUpdating: {
                    ...state.idCertVerifyStatusUpdating,
                    [payload.certType] : {
                        ...state.idCertVerifyStatusUpdating[payload.certType],
                        [payload.userId]: false
                    }
                },
                idCertVerification: {
                    ...state.idCertVerification,
                    [payload.certType] : {
                        ...state.idCertVerification[payload.certType],
                        [payload.userId]: {
                            ...state.idCertVerification[payload.certType][payload.userId],
                            certificate: {
                                ...state.idCertVerification[payload.certType][payload.userId].certificate,
                                [payload.certType] : {
                                    ...state.idCertVerification[payload.certType][payload.userId].certificate[payload.certType],
                                    ...payload.verifyData
                                }

                            }
                        }    
                    }
                }
            }
        }
        case UPDATE_CURRENT_ID_CERT_TAB : {
            return {
                ...state,
                currentTab: payload
            }
        }
        default: {
            return state;
        }
    }
}

export default idCertVerificationReducer;