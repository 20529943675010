import {
  FETCH_BLOG,
  SET_BLOG,
  UPDATE_CURRENT_BLOG_TAB,
  SET_THE_ARTICLE,
  SET_THE_VIDEO,
  SET_HOW_TO_START,
  SET_EXAMPLES,
  SET_FEATURES,
  SET_BLOG_HOME
} from './actionTypes';

import {
  uiStartLoading,
  uiStopLoading, 
  authGetToken,
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchBlogPending = () => ({
  type: `${FETCH_BLOG}_PENDING`
});

export const fetchBlogRejected = (err) => ({
  type: `${FETCH_BLOG}_REJECTED`,
  payload: err
});

export const fetchBlogFulFilled = (blog) => ({
  type: `${FETCH_BLOG}_FULFILLED`,
  payload: blog
});

export const fetchBlog = (id) => {
  return async dispatch => {
      dispatch(fetchBlogPending());
      try{
          let token = await dispatch(authGetToken());
          let blog = await taguJamApi.fetchBlog(id, token);
          dispatch(fetchBlogFulFilled(blog))
          return blog;
      } catch(err){
          dispatch(fetchBlogRejected(err));
          throw err
      }
  }   
};

export const setBlog = (blog) => ({
  type: SET_BLOG,
  payload: blog
});

export const setTheArticle = (theArticle) => ({
  type: SET_THE_ARTICLE,
  payload: theArticle
});

export const updateBlogTab = (currentTab) =>({
  type: UPDATE_CURRENT_BLOG_TAB,
  payload : currentTab
});

export const saveTheArticle = (articleData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveTheArticle"));
    try{
      let token = await dispatch(authGetToken());
      let theArticle = await taguJamApi.saveTheArticle(articleData, token);
      dispatch(setTheArticle(theArticle));
      dispatch(uiStopLoading("saveTheArticle"));
      return theArticle
    } catch (err){
      dispatch(uiStopLoading("saveTheArticle"));
      console.log(err);
      throw err;    
    }
  }
}

export const setTheVideo = (theVideo) => ({
  type: SET_THE_VIDEO,
  payload: theVideo
});

export const saveTheVideo = (videoData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveTheVideo"));
    try{
      let token = await dispatch(authGetToken());
      let theVideo = await taguJamApi.saveTheVideo(videoData, token);
      dispatch(setTheVideo(theVideo));
      dispatch(uiStopLoading("saveTheVideo"));
      return theVideo
    } catch (err){
      dispatch(uiStopLoading("saveTheVideo"));
      console.log(err);
      throw err;    
    }
  }
}

export const setHowToStart = (howToStart) => ({
  type: SET_HOW_TO_START,
  payload: howToStart
});

export const saveHowToStart= (howToStartData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveHowToStart"));
    try{
      let token = await dispatch(authGetToken());
      let howToStart = await taguJamApi.saveHowToStart(howToStartData, token);
      dispatch(setHowToStart(howToStart));
      dispatch(uiStopLoading("saveHowToStart"));
      return howToStart
    } catch (err){
      dispatch(uiStopLoading("saveHowToStart"));
      console.log(err);
      throw err;    
    }
  }
}

export const setExamples = (examples) => ({
  type: SET_EXAMPLES,
  payload: examples
});

export const saveExamples= (examplesData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveExamples"));
    try{
      let token = await dispatch(authGetToken());
      let examples = await taguJamApi.saveExamples(examplesData, token);
      dispatch(setExamples(examples));
      dispatch(uiStopLoading("saveExamples"));
      return examples
    } catch (err){
      dispatch(uiStopLoading("saveExamples"));
      console.log(err);
      throw err;    
    }
  }
}

export const setFeatures = (features) => ({
  type: SET_FEATURES,
  payload: features
});

export const saveFeatures= (featuresData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveFeatures"));
    try{
      let token = await dispatch(authGetToken());
      let features = await taguJamApi.saveFeatures(featuresData, token);
      dispatch(setFeatures(features));
      dispatch(uiStopLoading("saveFeatures"));
      return features
    } catch (err){
      dispatch(uiStopLoading("saveFeatures"));
      console.log(err);
      throw err;    
    }
  }
}

export const setBlogHome = (blogHome) => ({
  type: SET_BLOG_HOME,
  payload: blogHome
});

export const saveBlogHome= (blogHomeData) => {
  return async dispatch => {
    dispatch(uiStartLoading("saveBlogHome"));
    try{
      let token = await dispatch(authGetToken());
      let blogHome = await taguJamApi.saveBlogHome(blogHomeData, token);
      dispatch(setBlogHome(blogHome));
      dispatch(uiStopLoading("saveBlogHome"));
      return blogHome
    } catch (err){
      dispatch(uiStopLoading("saveBlogHome"));
      console.log(err);
      throw err;    
    }
  }
}