/* ui */
export const UPDATE_CURRENT_HELP_TAB='UPDATE_CURRENT_HELP_TAB';
export const UPDATE_CURRENT_HELP_MODE='UPDATE_CURRENT_HELP_MODE';
export const TOGGLE_HELP_MENU='TOGGLE_HELP_MENU';
export const TOGGLE_AUTHWIN_OPEN='TOGGLE_AUTHWIN_OPEN';
export const INIT_AUTH_UI_STATE='INIT_AUTH_UI_STATE';
export const UI_START_LOADING='UI_START_LOADING';
export const UI_STOP_LOADING='UI_STOP_LOADING';
export const TOGGLE_ERRORWIN_OPEN='TOGGLE_ERRORWIN_OPEN';
export const INIT_ERRORWIN_STATE='INIT_ERRORWIN_STATE';
export const TOGGLE_ADMIN_MENU='TOGGLE_ADMIN_MENU';
export const SET_ERROR='SET_ERROR';
export const TOGGLE_SUCCESS_OPEN='TOGGLE_SUCCESS_OPEN';
export const INIT_SUCCESSWIN_STATE='INIT_SUCCESSWIN_STATE';

/* appData */
export const FETCH_APP_DATA='FETCH_APP_DATA';

/* local */
export const SET_LOCAL='SET_LOCAL';

/* auth */
export const TRY_AUTH_START='TRY_AUTH_START';
export const TRY_AUTH_END='TRY_AUTH_END';
export const SET_AUTH='SET_AUTH';
export const SET_AUTH_ERROR='SET_AUTH_ERROR';
export const SET_AUTH_SUCCESS='SET_AUTH_SUCCESS';

/* userMeta */
export const FETCH_USER_META='FETCH_USER_META';
export const FETCH_THE_USER_META='FETCH_THE_USER_META';
export const ADD_USER_META_TO_LIST='ADD_USER_META_TO_LIST';
export const SET_THE_PREFERENCE='SET_THE_PREFERENCE';
export const SET_THE_MAIL_SUBSCRIPTION='SET_THE_MAIL_SUBSCRIPTION';
export const SET_EMAIL_VERIFICATION_STATUS='SET_EMAIL_VERIFICATION_STATUS';
export const UPDATE_THE_USERMETA='UPDATE_THE_USERMETA';
export const SET_ACCOUNT_INFO_TO_META='SET_ACCOUNT_INFO_TO_META';
export const CHANGE_EMAIL_AND_RELATED='CHANGE_EMAIL_AND_RELATED';

/* adminLevel */
export const SET_USER_LEVEL='SET_USER_LEVEL';

/* idCertVerification */
export const FETCH_ID_CERT_VERIFICATION='FETCH_ID_CERT_VERIFICATION';
export const UPDATE_CURRENT_ID_CERT_TAB='UPDATE_CURRENT_ID_CERT_TAB';
export const UPDATE_VERIFICATION_STATUS='UPDATE_VERIFICATION_STATUS';

/* customerSupport */
export const FETCH_SUPPORT_CASES='FETCH_SUPPORT_CASES';
export const SET_ASSIGNEE='SET_ASSIGNEE';
export const SEND_SUPPORT='SEND_SUPPORT';
export const SET_NEW_MESSAGE='SET_NEW_MESSAGE';

/* notification */
export const SET_NOTIFICATION='SET_NOTIFICATION';
export const FETCH_NOTIFICATIONS='FETCH_NOTIFICATIONS';
export const UPDATE_CURRENT_NOTICE_TAB='UPDATE_CURRENT_NOTICE_TAB';

/* gigs */
export const FETCH_GIGS='FETCH_GIGS';
export const UPDATE_THE_GIG='UPDATE_THE_GIG';

/* deactivateRequest */
export const SET_DEACTIVATE='SET_DEACTIVATE';
export const FETCH_DEACTIVATIONS='FETCH_DEACTIVATIONS';
export const SET_THE_DEACTIVATE='SET_THE_DEACTIVATE';

/* messages */
export const FETCH_NO_ITEM_RETURNED_DEALS='FETCH_NO_ITEM_RETURNED_DEALS';
export const SET_ASSIGNEE_OF_DEAL='SET_ASSIGNEE_OF_DEAL';
export const SEND_MESSAGE='SEND_MESSAGE';

/* payment */
export const CHARGE_PAYMENT="CHARGE_PAYMENT";
export const CHARGE_REVERSE="CHARGE_REVERSE";
export const RESET_CHARGE_ERROR="RESET_CHARGE_ERROR";

/* sales */
export const SET_THE_SALES="SET_THE_SALES";
export const FETCH_ALL_SALES="FETCH_ALL_SALES";


/* user */
export const FETCH_THE_USER="FETCH_THE_USER";

/* accountInfo */
export const FETCH_ACCOUNT_INFO='FETCH_ACCOUNT_INFO';
export const SET_ACCOUNT_INFO='SET_ACCOUNT_INFO';

/* userInfo */
export const FETCH_USER_INFO='FETCH_USER_INFO';
export const SET_USER_INFO='SET_USER_INFO';
export const SET_EMAIL_CODE='SET_EMAIL_CODE';
export const SET_VERIFY_STATUS='SET_VERIFY_STATUS';
export const RESET_EMAIL_VERIFICATION='RESET_EMAIL_VERIFICATION';

/* bank */
export const FETCH_BANK='FETCH_BANK';
export const SET_BANK='SET_BANK';

/* userAddress */
export const FETCH_USER_ADDRESS='FETCH_USER_ADDRESS';
export const SET_USER_ADDRESS='SET_USER_ADDRESS';
export const SET_THE_USER_ADDRESS='SET_THE_USER_ADDRESS';
export const DELETE_THE_USER_ADDRESS='DELETE_THE_USER_ADDRESS';

/*blog */
export const FETCH_BLOG='FETCH_BLOG';
export const SET_BLOG='SET_BLOG';
export const UPDATE_CURRENT_BLOG_TAB='UPDATE_CURRENT_BLOG_TAB';
export const SET_THE_ARTICLE='SET_THE_ARTICLE';
export const SET_THE_VIDEO='SET_THE_VIDEO';
export const SET_HOW_TO_START='SET_HOW_TO_START';
export const SET_EXAMPLES='SET_EXAMPLES';
export const SET_FEATURES='SET_FEATURES';
export const SET_BLOG_HOME='SET_BLOG_HOM';