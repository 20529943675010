import {
    FETCH_NO_ITEM_RETURNED_DEALS,
    SET_ASSIGNEE_OF_DEAL,
    SEND_MESSAGE    
} from './actionTypes';
import {
    authGetToken,
    uiStartLoading, 
    uiStopLoading,
    setError
} from './index';
import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

const fetchNoItemReturnedDealsPending = () =>({
    type: `${FETCH_NO_ITEM_RETURNED_DEALS}_PENDING`
});

const fetchNoItemReturnedDealsFulFilled = (messages) =>({
    type: `${FETCH_NO_ITEM_RETURNED_DEALS}_FULFILLED`,
    payload: messages
});

const fetchNoItemReturnedDealsRejected = (error) =>({
    type: `${FETCH_NO_ITEM_RETURNED_DEALS}_REJECTED`,
    payload: error
});

export const fetchNoItemReturnedDeals = () => {
    return async dispatch => {
        dispatch(fetchNoItemReturnedDealsPending());
        try{
            let token = await dispatch(authGetToken()); 
            const messages = await taguJamApi.fetchNoItemReturnedDeals(token);
            dispatch(fetchNoItemReturnedDealsFulFilled(messages));
            return messages;
        } catch(err) {
            dispatch(fetchNoItemReturnedDealsRejected(err));
            console.log(err);
            return err;
        }
    }
}

const setAssigneeOfDeal = (dealId, userId) =>({
    type: SET_ASSIGNEE_OF_DEAL,
    payload: {dealId, userId}
});


export const updateAssigneeOfDeal = (dealId, userId) => {
    return async dispatch => {
        dispatch(uiStartLoading(`assignee_${dealId}`));
        try{
            let token = await dispatch(authGetToken()); 
            let updateAssigneeResult = await taguJamApi.updateAssigneeOfDeal(dealId, userId, token);
            dispatch(setAssigneeOfDeal(dealId, userId, updateAssigneeResult.status));
            dispatch(uiStopLoading(`assignee_${dealId}`));
            return updateAssigneeResult;  
        } catch(err){
            dispatch(uiStopLoading(`assignee_${dealId}`));
            dispatch(setError(`assignee_${dealId}`, err));
            console.log(err);
            throw err;
        }
    }
}

export const sendMessagePending = () => ({
    type: `${SEND_MESSAGE}_PENDING`
});

export const sendMessageRejected = (err) => ({
    type: `${SEND_MESSAGE}_REJECTED`,
    payload: err
});

export const sendMessageFulFilled = (message, dealId) => ({
    type: `${SEND_MESSAGE}_FULFILLED`,
    payload: {message, dealId}
});

export const sendOrderMessage = (dealId, messageData, orderData, status, activeOrderId, chargeDeposit) =>{
    return async dispatch => {
        dispatch(sendMessagePending());
        try{
            let token = await dispatch(authGetToken()); 
            let message = await taguJamApi.sendOrderMessage(messageData, dealId, token, orderData, status, activeOrderId, chargeDeposit);
            dispatch(sendMessageFulFilled(message, dealId))
            return message;
        } catch(err){
            dispatch(sendMessageRejected(err));
            throw err
        }
    }   
};
