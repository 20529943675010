import {
    SET_USER_LEVEL
} from '../actions/actionTypes';

const initState ={
    userLevel: "NONE"
}

const userLevelReducer = (state=initState, action) => {
    const {type, payload} = action;
    switch(type) {
        case SET_USER_LEVEL: {
            return{
                userLevel: payload    
            }
        }
        default: {
            return state;
        }
    }
}

export default userLevelReducer;