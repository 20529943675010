const LinkNames = {
    "USER_INFO":"UserInfo",
    "ACCOUNT_INFO":"AccountInfo",
    "ADDRESS_LIST":"Address",
    "SECURITY_SETTING":"SecuritySetting",
}

export const getAccountMenuList = (AccountMenuItems) => {
    let AccountMenuList = [];
    for(let key in AccountMenuItems){
        const { label, iconName } = AccountMenuItems[key];
        if(label === 'USER_INFO' || label === 'ACCOUNT_INFO' || label === 'ADDRESS_LIST' || label === 'SECURITY_SETTING'){
            let linkName = LinkNames[label];
            AccountMenuList.push({label, linkName, iconName});    
        }
    }
    return AccountMenuList;     
};
