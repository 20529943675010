import moment from 'moment';

const isValideEmail = val => {
    let isValid = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? 'INVALID_EMAIL' : null
    }
}

const isValideMinLength = (val, minLength) => {
    let isValid;
    if(val){
        isValid =val.length >= minLength;    
    } else {
        isValid = false;
    }
    return {
        isValid,
        error: !isValid ? `INVALID_LENGTH` : null
    }
};

const isEqualTo = (val, checkValue) => {
    let isValid = val === checkValue;
    return {
        isValid,
        error: !isValid ? "VALUE_NOT_MATCH" : null
    }    
};

const isXDigits = (val, number) => {
    let isValid;
    let regEx = new RegExp(`^[0-9]{${number}}$`)
    if(val){
        val.trim();
    }
    if(val){
        isValid = regEx.test(
            val
        );
    } else {
        isValid = false;   
    }
    return {
        isValid,
        error: !isValid ? "INVALID_VALUE" : null
    }     
}

const isNumberAndPoint= (val) => {
    let isValid;
    if(val){
        val.trim();
    }
    if(val){
        isValid = /^\d{1}(\.\d{1})?$/.test(
            val
        );
    } else {
        isValid = false;   
    }
    return {
        isValid,
        error: !isValid ? "INVALID_VALUE" : null
    }    
};

const isNumber = (val) => {
    let isValid;
    if(val){
        val.trim();
    }
    if(val){
        isValid = /^[0-9]+$/.test(
            val
        );
    } else {
        isValid = false;   
    }
    return {
        isValid,
        error: !isValid ? "INVALID_VALUE" : null
    }    
};

const noNumber = (val) => {
    let isValid;
    let hasNumber
    if(val){
        val.trim();
    }
    if(val){
        hasNumber = /[0-9０-９]/.test(
            val
        );
        isValid = !hasNumber;
    } else {
        isValid = false;   
    }
    return {
        isValid,
        error: !isValid ? "NO_NUMBER" : null
    }    
};

const postalCode = (val) => {
    let isValid = /\d{3}-\d{4}/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? 'INVALID_POSTAL_CODE' : null
    }
};

const bankAccount = (val) => {
    if(val){
        val = val.trim();
    }
    let isValid = /^\d{7}$/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? "INVALID_BANKACCOUNT_NUMBER" : null
    }
};

const isKana = (val) => {
    let isValid = /^[\u30A1-\u30FA]+$/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? "INVALID_VALUE" : null
    }    
}

const bankAccountName = (val) => {
    let isValid = /^[\u30A1-\u30FA]+\s[\u30A1-\u30FA]+$/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? "INVALID_BANKACCOUNT_NANE" : null
    }
}

const hasValue = (val) => {
    if(val){
        val = val.trim();
    }
    let isValid = val !== null && val !== '';
    return {
        isValid,
        error: !isValid ? "NO_VALUE" : null
    }    
};

const hasTime = (val) => {
    let isValid = val !== null;
    return {
        isValid,
        error: !isValid ? "NO_VALUE" : null
    }    
};

const hasInValidChar = (val) => {
    let isValid = true;
    if(val){
        val = val.trim();
    } 
    if(val){
        val = val.trim();
        const hasInValidChar = hasInValidCharCheck(val);
        isValid = !hasInValidChar;
        return {
            isValid,
            error: !isValid ? "INVALID_CHAR" : null
        }  
    } else {
        isValid = false;
        return {
            isValid,
            error: !isValid ? "NO_VALUE" : null
        }     
    }   
}

const isBirthDate = (val) => {
    let isValid; 
    let errorMsg = null;
    let birthDay = moment(val, 'YYYY-MM-DD');
    let today = moment();
    if(val){
        if (today < birthDay) {
        // date is future
            isValid = false;
            errorMsg = "INVALID_VALUE"; 
        } else {
            isValid = true; 
        }
    } else {
        isValid = false;
        errorMsg = "INVALID_VALUE";    
    } 
    return {
        isValid,
        error: !isValid ? errorMsg : null
    }       
}

const isExpeirdDateValid = (val, needExpCheck) => {
    let isValid; 
    let errorMsg = null; 
    let expDate = moment(val, 'YYYY-MM-DD');
    let today = moment();
    if(needExpCheck===true){
        if (today > expDate) {
        // date is past
            isValid = false;
            errorMsg = "EXPIRED_DATE"; 
        } else {
            isValid = true; 
        }
    } else {
        isValid = true    
    } 
    return {
        isValid,
        error: !isValid ? errorMsg : null
    }  
}

const isStartHr = (val, end_hr) => {
    let isValid; 
    let errorMsg = null;
    if(val){
        if(end_hr){
            let end = moment(end_hr);
            let start = moment(val);
            if( start < end){
                isValid = true;
            } else {
                isValid = false;
                errorMsg = "INVALID_TIME";    
            }
        } else {
            isValid = true;
        }
    } else {
        isValid = false;
        errorMsg = "INVALID_TIME";     
    }
    return {
        isValid,
        error: !isValid ? errorMsg : null
    }        
}

const isEndHr = (val, start_hr) => {
    let isValid; 
    let errorMsg = null;
    if(val){
        if(start_hr){
            let start = moment(start_hr);
            let end = moment(val);
            if( start < end){
                isValid = true;
            } else {
                isValid = false;
                errorMsg = "INVALID_TIME";    
            }
        } else {
            isValid = true;
        }
    } else {
        isValid = false;
        errorMsg = "INVALID_TIME";     
    }
    return {
        isValid,
        error: !isValid ? errorMsg : null
    }      
}

const hasInValidCharCheck = (val) => {
    return /[?<>{}[\]*&%:？＜＞『』：・＆％]+/.test(
        val
    );
}

const hasValueThenhasInValidChar = (val) =>{
    let isValid = true;
    if(val){
        val = val.trim();
        const hasInValidChar = hasInValidCharCheck(val);
        isValid = !hasInValidChar;
    }
    return {
        isValid,
        error: !isValid ? "INVALID_CHAR" : null
    }  
}

const isMonth = (val, expYear) => {
    let isValid, 
        errorMsg=null;
    isValid = /^(0[1-9]|1[0-2])$/.test(
        val
    );
    if(isValid===true){
        if(isYear(expYear).isValid === true){
            expYear = parseInt("20"+expYear);
            const expMonth = parseInt(val);
            const thisYear = new Date().getFullYear();
            const thisMonth = new Date().getMonth()+1;
            if(expYear===thisYear){
                isValid = (thisMonth <= expMonth)    
            };
            errorMsg = isValid===true? null: "EXPIRED_CARD"
        }
    } else {
        errorMsg="INVALID_VALUE" 
    }
    return {
        isValid,
        error: errorMsg
    }   
}

const isYear = (val) => {
    let isValid, 
        errorMsg=null;
    isValid = /^\d{2}$/.test(
        val
    );
    if(isValid===true){
        const thisYear = new Date().getFullYear();
        const expYear = parseInt("20"+val);
        isValid = (thisYear <= expYear);
        errorMsg = isValid===true? null: "EXPIRED_CARD"
    } else {
        errorMsg="INVALID_VALUE" 
    }
    return {
        isValid,
        error: errorMsg
    }     
}

const isCVV = (val, cardType) => {
    let isValid;
    if(cardType === 'AMEX'){
        isValid = /^\d{4}$/.test(
            val
        );
    } else if (cardType === 'VISA' || cardType === 'MASTER' || cardType === 'JCB' || cardType === 'DINER') {
        isValid = /^\d{3}$/.test(
            val
        ); 
    } else {
        isValid = /^\d{3,4}$/.test(
            val
        );    
    }    

    return {
        isValid,
        error: !isValid ? "INVALID_VALUE" : null
    }     
}

const isCardHolderName = (val) => {
    let isValid = /^[A-Z]+\s[A-Z]+$/.test(
        val
    );
    return {
        isValid,
        error: !isValid ? "INVALID_CARD_HOLDER_NAME" : null
    }
}

const Validate = (val, rules, pairedVal) => {
    let isValid = true;
        let error = [];
        for(let rule in rules){
           switch(rule) {
                case 'isEmail': {
                    const result = isValideEmail(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'minLength': {
                    const result = isValideMinLength(val, rules[rule]); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'hasValue': {
                    const result = isValid && hasValue(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'hasTime':{
                    const result = isValid && hasTime(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isBirthDate': {
                    const result = isValid && isBirthDate(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isExpeirdDateValid': {
                    const result = isValid && isExpeirdDateValid(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'hasInValidChar': {
                    const result = isValid && hasInValidChar(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'hasValueThenhasInValidChar': {
                    const result = isValid && hasValueThenhasInValidChar(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isCardHolderName': {
                    const result = isValid && isCardHolderName(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isStartHr':{
                    const result = isValid && isStartHr(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;     
                }
                case 'isEndHr':{
                    const result = isValid && isEndHr(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;     
                }
                case 'isMonth': {
                    const result = isValid && isMonth(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isYear': {
                    const result = isValid && isYear(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isCVV': {
                    const result = isValid && isCVV(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isNumber': {
                    const result = isValid && isNumber(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isNumberAndPoint': {
                    const result = isValid && isNumberAndPoint(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'noNumber': {
                    const result = isValid && noNumber(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isKana': {
                    const result = isValid && isKana(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isXDigits': {
                    const result = isValid && isXDigits(val, rules[rule]); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;    
                }
                case 'isPostalCode': {
                    const result = isValid && postalCode(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isBankAccount': {
                    const result = isValid && bankAccount(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'isBankAccountName': {
                    const result = isValid && bankAccountName(val); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                case 'equalTo': {
                    const result = isValid && isEqualTo(val, pairedVal); 
                    isValid = isValid && result.isValid;
                    if(result.error){
                        error.push(result.error)
                    }
                    break;
                }
                default:
                    isValid = true;
           }    
        }
        return { isValid, error };
}

export default Validate;
