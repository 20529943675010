import {
    SET_NOTIFICATION,
    FETCH_NOTIFICATIONS,
    UPDATE_CURRENT_NOTICE_TAB
} from '../actions/actionTypes';

const initState = {
    user:null,
    userIsLoading: false,
    userError:null,
    system: null,
    sytemIsLoading:false,
    systemError:null,
    currentNoticeTab: 'System'
}

const processObject = (prevState, newData) => {
    if(prevState){
        return{
            ...prevState,
            ...newData
        }
    } else {
        return{
            ...newData
        }
    }
}

const notificationsReducer = (state=initState, action) => {
    const {type, payload} = action;
    switch(type){
        case SET_NOTIFICATION:{
            let newNotifyData = {
                [payload.notifyData.id]: {
                    ...payload.notifyData    
                }
            };
            let newData = null;
            if(payload.userId){
                newData = {
                    [payload.userId]: processObject( 
                        state[payload.type] && state[payload.type].hasOwnProperty(payload.userId) 
                            ? state[payload.type][payload.userId] : null, 
                            newNotifyData)
                };
            } else {
               newData = newNotifyData; 
            }
            return{
                ...state,
                [payload.type]: processObject(state[payload.type], newData)
            }
        }
        case `${FETCH_NOTIFICATIONS}_PENDING`: {
            return{
                ...state,
                [`${payload.type}IsLoading`]:true,
                [`${payload.type}Error`]:null
            }
        }
        case `${FETCH_NOTIFICATIONS}_REJECTED`: {
            return{
                ...state,
                [`${payload.type}IsLoading`]:false,
                [`${payload.type}Error`]:payload
            }
        }
        case `${FETCH_NOTIFICATIONS}_FULFILLED`: {
            return{
                ...state,
                [payload.type]:payload.notifications,
                [`${payload.type}IsLoading`]:false,
                [`${payload.type}Error`]:null
            }
        }
        case UPDATE_CURRENT_NOTICE_TAB: {
            return {
                ...state,
                currentNoticeTab: payload
            }
        }
        default: {
            return state;
        }
    }
}

export default notificationsReducer;
    