import {
    TRY_AUTH_START,
    TRY_AUTH_END,
    SET_AUTH,
    SET_AUTH_ERROR,
    SET_AUTH_SUCCESS
} from '../actions/actionTypes';

const initState = {
    authIsLoading: false,
    authInfo: {
        token : null,
        expirationDate : null,
        userId : null,
        email : null
    },
    authError:null,
    authSuccess:null,
}

const authReducer = (state=initState, action) => {
    const {payload, type} = action;
    switch(type){
        case TRY_AUTH_START: 
            return {
                ...state,
                authIsLoading: true,
                authError:null
            }
        case SET_AUTH:
            return {
                ...state,
                authIsLoading: false,
                authInfo: action.payload
            }
        case TRY_AUTH_END:
            return {
                ...state,
                authIsLoading: false
            }
        case SET_AUTH_ERROR:
            return {
                ...state,
                authError:payload
            }
        case SET_AUTH_SUCCESS:
            return {
                ...state,
                authSuccess:payload
            }
        default: 
            return state;
    }
};

export default authReducer;
