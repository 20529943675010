import {
    FETCH_SUPPORT_CASES,
    SET_ASSIGNEE,
    SEND_SUPPORT,
    SET_NEW_MESSAGE    
} from '../actions/actionTypes';

const initState = {
    supportCases:null,
    supportCasesPending:false,
    supportCasesError:null,
}

const supportCasesReducer = (state=initState, action) => {
    const {type, payload} = action;
    switch(type){
        case `${FETCH_SUPPORT_CASES }_PENDING` : {
            return{
                ...state,
                supportCasesPending:false,
                supportCasesError:null
            }
        }
        case `${FETCH_SUPPORT_CASES }_REJECTED` : {
            return {
                ...state,
                supportCasesPending:false,
                supportCasesError:payload
            }
        }
        case `${FETCH_SUPPORT_CASES }_FULFILLED` : {
            return {
                ...state,
                supportCasesPending:false,
                supportCases:payload
            }
        }
        case SET_ASSIGNEE : {
            return {
                ...state,
                supportCases: {
                    ...state.supportCases,
                    [payload.supportId]: {
                        ...state.supportCases[payload.supportId],
                        assignee: payload.userId,
                        status: payload.status ? payload.status : state.supportCases[payload.supportId].status 
                    }
                }
            }
        }
        case SET_NEW_MESSAGE:{
            return {
                ...state,
                supportCases: {
                    ...state.supportCases,
                    [payload.supportId]: {
                        ...state.supportCases[payload.supportId],
                        msg: {
                            ...state.supportCases[payload.supportId].msg,
                            [payload.newMsg.id]: {
                                ...payload.newMsg
                            }
                        } 
                    }
                }
            }
        }
        case `${SEND_SUPPORT}_FULFILLED`:
            return {
                ...state,
                supportCases: {
                    ...state.supportCases,
                    [payload.supportId]: {
                        ...state.supportCases[payload.supportId],
                        msg: {
                            ...state.supportCases[payload.supportId].msg,
                            [payload.newMsg.id]: {
                                ...payload.newMsg
                            }
                        },
                        status: payload.supportData.status 
                    }
                }
            }
        default: {
            return state;
        }
    }
}

export default supportCasesReducer;