import {
    FETCH_GIGS,
    UPDATE_THE_GIG
} from '../actions/actionTypes';

const initState = {
    gigsIsLoading: false,
    gigs: null,
    gigsError: false
};

const gigReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case `${FETCH_GIGS}_PENDING`:{
            return {
                ...state,
                gigsIsLoading: true,
                gigsError: false
            };
        }
        case `${FETCH_GIGS}_FULFILLED`:{
            return {
                ...state,
                gigs: payload,
                gigsIsLoading: false,
                gigsError: false
            };
        }
        case `${FETCH_GIGS}_REJECTED`:{
            return {
                ...state,
                gigs: [],
                gigsIsLoading: false,
                gigsError: {
                    message: 'Database error',
                    err: payload
                }
            };
        }
        case UPDATE_THE_GIG : {
            return{
                ...state,
                gigs:{
                    ...state.gigs,
                    [payload.gigId]:{
                        ...state.gigs[payload.gigId],
                        ...payload.updateData
                    }
                }
            }    
        }
        default:
            return state;
    }
};

export default gigReducer;
