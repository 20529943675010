import {
    UPDATE_CURRENT_HELP_TAB,
    UPDATE_CURRENT_HELP_MODE,
    TOGGLE_HELP_MENU,
    TOGGLE_AUTHWIN_OPEN,
    INIT_AUTH_UI_STATE,
    UI_START_LOADING,
    UI_STOP_LOADING,
    TOGGLE_ERRORWIN_OPEN,
    INIT_ERRORWIN_STATE,
    TOGGLE_ADMIN_MENU,
    SET_ERROR,
    TOGGLE_SUCCESS_OPEN,
    INIT_SUCCESSWIN_STATE
} from '../actions/actionTypes'

const initState = {
    Help : {
        currentTab:'HELP',
        currentMode:'SELL',
        showSideMenu: false
    },
    Admin : {
        showAdminSideMenu: false
    },
    Auth: {
        authWinOpen: false,
        mode:"SIGNIN",
        closable: true
    },
    uiError: {
        errorWinOpen: false,
        closable: true,
        systemError:null    
    },
    uiSuccess: {
        successWinOpen: false,
        message:null    
    },
    isLoading:{},
}

const reducer = (state=initState, action) => {
    const { type, payload} = action;
    switch (type){
        case INIT_AUTH_UI_STATE:
            return{
                ...state,
                Auth:{
                    ...state.Auth,
                    ...payload
                }   
            }
        case TOGGLE_AUTHWIN_OPEN:
            return{
                ...state,
                Auth:{
                    ...state.Auth,
                    authWinOpen: !state.Auth.authWinOpen
                }   
            }
        case UPDATE_CURRENT_HELP_TAB:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    currentTab: payload
                }
            }
        case UPDATE_CURRENT_HELP_MODE:
                return {
                    ...state,
                    Help: {
                        ...state.Help,
                        currentMode: payload
                    }
                }
        case TOGGLE_HELP_MENU:
            return {
                ...state,
                Help: {
                    ...state.Help,
                    showSideMenu: payload
                }
            }
        case UI_START_LOADING:
            return{
                ...state,
                isLoading:{
                    ...state.isLoading,
                    [payload]:true
                }
            }
        case UI_STOP_LOADING:
            return{
                ...state,
                isLoading:{
                    ...state.isLoading,
                    [payload]:false
                }
            }
        case INIT_ERRORWIN_STATE:
            return{
                ...state,
                uiError:{
                    ...state.uiError,
                    ...payload
                }   
            }
        case TOGGLE_ERRORWIN_OPEN:
            return{
                ...state,
                uiError:{
                    ...state.uiError,
                    errorWinOpen: !state.uiError.errorWinOpen
                }   
            }
        case TOGGLE_ADMIN_MENU:
            return {
                ...state,
                Admin: {
                    ...state.Admin,
                    showAdminSideMenu: payload
                }
            }
        case SET_ERROR: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [payload.key]: payload.error
                }
            }
        }
        case TOGGLE_SUCCESS_OPEN:
            return{
                ...state,
                uiSuccess:{
                    ...state.uiSuccess,
                    successWinOpen: !state.uiSuccess.successWinOpen
                }   
            }
        case INIT_SUCCESSWIN_STATE:
            return{
                ...state,
                uiSuccess:{
                    ...state.uiSuccess,
                    ...payload
                }   
            }  
        default:
            return state
    }    
}

export default reducer;