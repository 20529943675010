import {
    FETCH_USER_ADDRESS,
    SET_USER_ADDRESS,
    SET_THE_USER_ADDRESS,
    DELETE_THE_USER_ADDRESS,
} from './actionTypes';

import { 
    uiStartLoading, 
    uiStopLoading,
    authGetToken 
} from './index'

import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();

export const fetchUserAddressPending = () => ({
    type: `${FETCH_USER_ADDRESS}_PENDING`
});

export const fetchUserAddressRejected = (err) => ({
    type: `${FETCH_USER_ADDRESS}_REJECTED`,
    payload: err
});

export const fetchUserAddressFulFilled = (userAddress) => ({
    type: `${FETCH_USER_ADDRESS}_FULFILLED`,
    payload: userAddress
});

export const fetchUserAddress = (id) => {
    return async dispatch => {
        dispatch(fetchUserAddressPending());
        try{
            let token = await dispatch(authGetToken());
            let userAddress = await taguJamApi.fetchUserAddress(id, token);
            dispatch(fetchUserAddressFulFilled(userAddress))
            return userAddress;
        } catch(err){
            dispatch(fetchUserAddressRejected(err));
            throw err
        }
    }   
};

export const fetchTheUserAddress = (id, type) => {
    return async dispatch => {
        dispatch(uiStartLoading(`userAddress_${type}_${id}`));
        try{
            let token = await dispatch(authGetToken());
            let userAddress = await taguJamApi.fetchUserAddress(id, token, type);
            dispatch(setTheUserAddress(userAddress, type));
            dispatch(uiStopLoading(`userAddress_${type}_${id}`));
            return userAddress;
        } catch(err){
            dispatch(uiStopLoading(`userAddress_${type}_${id}`));
            console.log(err);
            throw err
        }
    }   
};

export const setTheUserAddress = (userAddress, type) => ({
    type: SET_THE_USER_ADDRESS,
    payload: {userAddress, type}
});

export const setUserAddress = (userAddress) => ({
    type: SET_USER_ADDRESS,
    payload: userAddress
});

export const saveUserAddress = ( userAddressData, id) => {
    return async dispatch => {
        dispatch(uiStartLoading(`saveUserAddress_${id}`));
        try{
            let token = await dispatch(authGetToken());
            let userBank = null;
            if(userAddressData.addressType === "YOUR_ADDRESS"){
                userBank = await taguJamApi.fetchUserBankMeta(id, token)
            }
            if(userBank){
                const recipientId = userBank.recipientId;
                const accountData = {
                    address: userAddressData    
                }
                // eslint-disable-next-line no-unused-vars
                const updateAddressOfBank = await taguJamApi.saveRecipient( accountData, id, recipientId, token )
            }
            let userAddress = await taguJamApi.saveUserAddress(userAddressData, id, token);
            dispatch(setTheUserAddress(userAddress, userAddressData.addressType.toLowerCase()));
            dispatch(uiStopLoading(`saveUserAddress_${id}`));
            return userAddress;  
        } catch(err){
            dispatch(uiStopLoading(`saveUserAddress_${id}`));
            console.log(err);
            throw err;
        }
    }
}

export const deleteTheUserAddress = (addressType) => {
    return {
        type: DELETE_THE_USER_ADDRESS,
        payload: addressType
    }
}

export const deleteUserAddress = (addressType, id) => {
    return async dispatch => {
        dispatch(uiStartLoading(`deleteUserAddress_${addressType}_${id}`));
        try{
            let token = await dispatch(authGetToken()); 
            let result = await taguJamApi.deleteUserAddress(addressType.toLowerCase(), id, token);
            dispatch(deleteTheUserAddress(addressType.toLowerCase()));
            dispatch(uiStopLoading(`deleteUserAddress_${addressType}_${id}`));
            return result;  
        } catch(err){
            dispatch(uiStopLoading(`deleteUserAddress_${addressType}_${id}`));
            console.log(err);
            throw err;
        }
    }    
}