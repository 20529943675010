import * as firebase from 'firebase';
import FirebaseConfig from './FirebaseConfig';

let instance = null
 
class firebaseApi {
  constructor() {
    if (!instance) {
      this.app = firebase.initializeApp(FirebaseConfig);
      instance = this;
    }
    return instance
  }

  authUser = async (email, password) => {
    try{
      const result = await firebase.auth().signInWithEmailAndPassword(email, password);
      return result;
    } catch(err) {
      throw err;
    }
  }

  authUserWToken = async (token) => {
    try{
      const result = await firebase.auth().signInWithCustomToken(token);
      return result;
    } catch(err) {
      throw err;
    }
  }

  getUser = async () => {
    try{
      const user = await firebase.auth().currentUser;
      return user;
    } catch(err) {
      throw err;
    }
  }

  signOut = async () => {
    try{
      const result = await firebase.auth().signOut();
      return result;
    } catch(err) {
      throw err;
    } 
  }

  getUserOnlineRef = (userId) => {
    console.log("userId", userId)
    return firebase.database().ref(`userMeta/${userId}/online`);
  }

  getLoggedUsersRef = () => {
    return firebase.database().ref("userMeta").orderByChild("online").equalTo(true);  
  }

  getChatMsgRef = (dealId, mode) => {
      console.log("dealId, mode", dealId, mode)
      return firebase.database().ref(`${mode}/${dealId}/msg`);
  }

  getProviderBlockRef = (dealId, mode) => {
    console.log("dealId, mode", dealId, mode)
    return firebase.database().ref(`${mode}/${dealId}/providerBlock`);  
  }

  getCustomerBlockRef= (dealId, mode) => {
    console.log("dealId, mode", dealId, mode)
    return firebase.database().ref(`${mode}/${dealId}/customerBlock`);  
  }

  getActivityRef = (userId, mode) => {
    console.log("userId, mode", userId, mode);
    return firebase.database().ref("messages").orderByChild(mode).equalTo(userId);
  }

  getNotificationRef = (userId, mode) => {
    console.log("userId, mode", userId, mode);
    let ref = mode === 'system'
      ? firebase.database().ref(`notifications/${mode}`)
      : firebase.database().ref(`notifications/${mode}/${userId}`)
    return ref;
  }

  getTimeStamp = () => {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  getUserLevel = async () => {
    try {
      let level = "NONE";
      let idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
      if(idTokenResult.claims.hasOwnProperty('Admin') && idTokenResult.claims.Admin){
          level = "GENERAL";  
      }
      if(idTokenResult.claims.hasOwnProperty('MasterAdmin') && idTokenResult.claims.MasterAdmin){
        level = "MASTER";  
      }
      return level;
    } catch (err) {
      throw err;
    } 
  }

  getCFMToken = async () => {
      try {
          let fcmToken = await localStorage.getItem("fcmToken");
          return fcmToken;
      } catch (err) {
          throw err;    
      }
  }
}
const  FirebaseApi = new firebaseApi();
export default FirebaseApi;
