import { createStore, combineReducers, applyMiddleware, compose  } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import uiReducer from './reducers/ui';
import appDataReducer from './reducers/appData';
import localReducer from './reducers/local';
import authReducer from './reducers/auth';
import userMetaReducer from './reducers/userMeta';
import gigsReducer from './reducers/gigs';
import userLevelReducer from './reducers/userLevel';
import idCertVerificationReducer from './reducers/idCertVerificaiton';
import customerSupportReducer from './reducers/customerSupport';
import notificationsReducer from './reducers/notifications';
import deactivationsReducer from './reducers/deactivations';
import messagesReducer from './reducers/messages';
import paymentReducer from './reducers/payment';
import salesReducer from './reducers/sales';
import userReducer from './reducers/user';
import accountInfoReducer from './reducers/accountInfo';
import bankReducer from './reducers/bank';
import userInfoReducer from './reducers/userInfo';
import userAddressReducer from './reducers/userAddress';
import blogReducer from './reducers/blog';


let composeEnhancers = process.env.NODE_ENV === 'development' 
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    : null;

if(!composeEnhancers){
    composeEnhancers = compose;   
}

const middlewares = [
    promiseMiddleware,
    thunk
];

const rootReducer = combineReducers({
    ui: uiReducer,
    appData: appDataReducer,
    local: localReducer,
    auth: authReducer,
    userMeta: userMetaReducer,
    gigs:gigsReducer,
    userLevel: userLevelReducer,
    idCertVerify: idCertVerificationReducer,
    customerSupport: customerSupportReducer,
    notifications: notificationsReducer,
    deactivations: deactivationsReducer,
    messages:messagesReducer,
    payment: paymentReducer,
    user: userReducer,
    sales:salesReducer,
    accountInfo: accountInfoReducer,
    userinfo: userInfoReducer,
    bank:bankReducer,
    userAddress: userAddressReducer,
    blog: blogReducer
});

const configureStore = () => {
    return createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
}

export default configureStore;