import React, { Component } from 'react';
import styles from './Home.module.css';
import Logo from  '../../components/Brand/Logo/Logo' ; 
import {connect} from 'react-redux';
import {
    toggleErrorWinOpen,
    initUiErrorState,
    fetchTheUserMeta
} from '../../store/actions';
import AddLocalStrings from '../../constants/AddLocalStrings';

class Home extends Component {
    render(){
        const { localCode } = this.props;
        return(
        <div className="MainPadding">
            <div className={styles.Home} style={{textAlign:'center'}}>
                <h2 className="PageHeader" style={{margin:'20px auto'}}>
                {AddLocalStrings[localCode].TAGUJAM_ADMIN_TOOL}
                </h2>
                <div style={{margin:'20px auto 50px auto'}}>
                    <Logo width={100} localCode={localCode} />
                </div>
            </div>
        </div>    
        );
    }
}

const mapStateToProps = ({
    local: { localCode },
    appData: {appData, appDataIsLoading, appDataError},
    auth:{authInfo},
    userMeta:{theUserMeta, theUserMetaIsLoading, theUserMetaError},
    userLevel: {userLevel}
}) => ({
    localCode,
    appData, appDataIsLoading, appDataError,
    authInfo,
    theUserMeta, theUserMetaIsLoading, theUserMetaError,
    userLevel  
});

const mapDispatchToProps = dispatch => ({
    getTheUserMeta: (userId) => dispatch(fetchTheUserMeta(userId)),
    onToggleErrorWinOpen : () => dispatch(toggleErrorWinOpen()),
    onInitUiErrorState : (initState) => dispatch(initUiErrorState(initState)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);