import {
    CHARGE_PAYMENT,
    RESET_CHARGE_ERROR,
    CHARGE_REVERSE
} from './actionTypes';

import { 
    authGetToken,
    saveTheSale,
    sendOrderMessage,
} from './index';
import TaguJamApi from '../../constants/TaguJamApi';

const taguJamApi = new TaguJamApi();


export const chargePaymentPending = () => ({
    type: `${CHARGE_PAYMENT}_PENDING`
});

export const failedCharge =(err) => ({
    type: `${CHARGE_PAYMENT}_FAILED`,
    payload: err    
});

export const chargePaymentFulFilled = () => ({
    type: `${CHARGE_PAYMENT}_FULFILLED`
});

export const resetChargeError = () => ({
    type: RESET_CHARGE_ERROR
});

const getMsgObj = (message, providerId, customerId, gigId, status, chargeDeposit) => {
    const data =  {
        customerId,
        customerRead : chargeDeposit? false : true,
        gigId,
        message,
        providerId,
        providerRead : false,
        to : "customerId,providerId",
        systemMsg: true,
        status
    }
    console.log("msg:", data);
    return data;
}

export　const chargeFee = (cancelPersent, chargeData, theOrder, dealId, providerId, customerId, gigId, localCode, messageStr, chargeDeposit) => {
    return async dispatch => {
        dispatch(chargePaymentPending());
        try{
            let token = await dispatch(authGetToken()); 
            let chargeDataResult = await taguJamApi.chargeServiceFee(chargeData, providerId, customerId, localCode, token);
            chargeDataResult = {
                ...chargeDataResult,
                profit: chargeData.profit,
                handlingFee: chargeData.handlingFee
            }
            // eslint-disable-next-line no-unused-vars
            let theSale = await dispatch(saveTheSale(chargeDataResult, theOrder, dealId, providerId, customerId, gigId));
            let status = cancelPersent ? 'CANCEL_FEE_PAYMENT_COMPLETED':'PAYMENT_COMPLETED';
            let msg = getMsgObj(messageStr, providerId, customerId, gigId, status, chargeDeposit);
            let orderData = (cancelPersent || chargeDeposit) ? theOrder : null;
            let activeOrderId = theOrder.id;
            // eslint-disable-next-line no-unused-vars
            let sendMessage = await dispatch(sendOrderMessage(dealId, msg, orderData, status, activeOrderId, chargeDeposit))
            dispatch(chargePaymentFulFilled());
            return chargeDataResult;  
        } catch(err){
            dispatch(failedCharge(err));
            console.log(err);
            throw err;
        }
    }    
}

export const reverseChargePending = () => ({
    type: `${CHARGE_REVERSE}_PENDING`
});

export const reverseChargeFailed =(err) => ({
    type: `${CHARGE_REVERSE}_FAILED`,
    payload: err    
});

export const reverseChargeFulFilled = () => ({
    type: `${CHARGE_REVERSE}_FULFILLED`
});

export　const reverseCharge= (chargeId, customerId, providerId ) => {
    return async dispatch => {
        dispatch(reverseChargePending());
        try{
            let token = await dispatch(authGetToken()); 
            let chargeDataResult = await taguJamApi.reverseCharge(chargeId, token);
            // eslint-disable-next-line no-unused-vars
            let theUpdateCharge = await taguJamApi.updateTheCharge(chargeId, chargeDataResult, customerId, providerId, token);
            dispatch(reverseChargeFulFilled());
            return chargeDataResult;  
        } catch(err){
            dispatch(reverseChargeFailed(err));
            console.log(err);
            throw err;
        }
    }    
}