import React from 'react';
import styles from './AvatorWithName.module.scss';
import Avator from '../Avator/Avator';
import AddLocalStrings from '../../../constants/AddLocalStrings';
import Aux from '../../../hoc/Aux/Aux';
import { trunicateString } from '../../../utils/'

const AvatorWithName = (props) => {
    const { localCode, accountInfo, id, tagujam, bgLight } = props;
    const displayName = (accountInfo && accountInfo.displayName) ? accountInfo.displayName : trunicateString(6, id);
    const alt = (accountInfo && accountInfo.displayName) ? `${accountInfo.displayName} ${AddLocalStrings[localCode].PHOTO}` : AddLocalStrings[localCode].PHOTO;
    const avator = (accountInfo && accountInfo.avator) ?  accountInfo.avator : null;
    return(
        <Aux>
        <Avator size={15} avator={avator} alt={alt} tagujam={tagujam} bgLight={bgLight} />
        <p className={styles.DisplayName}>{displayName}</p>
        </Aux>
    )
}

export default AvatorWithName;