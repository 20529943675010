import React from 'react';
import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner';

const Button = (props) => {
    const {disabled, loading, type, onClick, style, BtnType} = props;
    let typeStyles = BtnType ? styles[BtnType] : styles.Primary;
    return(
    <button 
        className={`${styles.Button} ${typeStyles}`}
        disabled={disabled}
        type={type}
        onClick={onClick}
        style={style}
    >
        { loading &&
        <div style={{ position:"absolute"}}>
            <Spinner />
        </div>
        }
        {props.children}
    </button>
    );
}

export default Button;