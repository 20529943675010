import moment from 'moment';

export default class TaguJamApi  {
    constructor(){
        //firebase path set up
        this.googleApiURL = process.env.REACT_APP_GOOGLE_API_URL;
        this.firebaseAPI_KEY = process.env.REACT_APP_FB_API_KEY;
        this.tokenApiURL = process.env.REACT_APP_TOKEN_API_URL;
        this.firebaseRoot = process.env.REACT_APP_FB_DATABASEURL;
        this.firebaseFunctionURL = process.env.REACT_APP_FB_FUNCTION_URL;
    }

    normalizedData = (Data) => {
        let DataList = [];
        for(let key in Data){
            const data = {
                ...Data[key],
                id: key
            }
            DataList.push(data);
        }
        return DataList;
    }
    
    local2ISO3166Code = (localCode) => {
        const local = {
            ja_jp:"jp",
            en_us:"us"    
        }
        return local[localCode];
    }

    localCurrency = (localCode) => {
        const local = {
            ja_jp:"jpy",
            en_us:"usd"    
        }
        return local[localCode];
    }

    getCurrentyUnit = (localCode) => {
        switch (localCode) {
            case 'jp_ja': return 'jpy'
            case 'en_us': return 'usd'
            default: return 'jpy'
        }
    }

    convertToArrayData = (Data) => {
        let DataList = [];
        for(let key in Data){
            DataList.push({id: key});
        }
        return DataList;
    }

    getFBLanguageCode = (localCode) => {
        const local = {
            ja_jp:"ja",
            en_us:"en"    
        }
        return local[localCode];
    }

    async tryAuth(authData, mode){
        const {email, password, platform, today, ipAddress} = authData;
        // mode could be "SIGNIN" ? "SIGNUP"
        const urlString = mode==="SIGNIN" ? "signInWithPassword" : "signUp";
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:${urlString}?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        email,
                        password,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            let { idToken, localId } = data;
            if(mode === 'SIGNUP'){
                // eslint-disable-next-line no-unused-vars
                let metaResult = await fetch(
                    `${this.firebaseRoot}/userMeta/${localId}.json?auth=${idToken}`,
                    {
                        method:"PUT",
                        body: JSON.stringify({
                            active:true,
                            id: localId,
                            preference: {
                                showDealingOnly:true,
                                showActiveInterviewOnly:true,
                                showActiveQuestionOnly:true     
                            },
                            mailSubscription:{
                                campaignMail:false,
                                dealPending:false,
                                systemNotification:false     
                            },
                            fcmTokens: {
                                [platform]:"weblogin"    
                            }
                        }),
                        headers:{
                            "Content-Type": "application/json"
                        }
                    }
                );
                // eslint-disable-next-line no-unused-vars
                let userResult = await fetch(
                    `${this.firebaseRoot}/users/${localId}.json?auth=${idToken}`,
                    {
                        method:"PUT",
                        body: JSON.stringify({
                            tos_acceptance: {
                                date: today,
                                ip: ipAddress
                            },
                            email:email
                        }),
                        headers:{
                            "Content-Type": "application/json"
                        }
                    }
                );
                // console.log(userResult);
            }
            return data;
        } catch (err){
            throw err;
        }
    }

    async sendResetPasswordMsg(email, localCode) {
        let lang = this.getFBLanguageCode(localCode);
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:sendOobCode?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        requestType:"PASSWORD_RESET",
                        email
                    }),
                    headers:{
                        "Content-Type": "application/json",
                        "X-Firebase-Locale":lang
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("sendResetPasswordMsgResult:", data);
                return data;
            } else {
                console.log("data fetch error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updatePassword(newPassword, token) {
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:update?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        idToken:token,
                        password: newPassword,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("update password:", data);
                return data;
            } else {
                console.log("update password error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updateEmail(newEmail, userId, token) {
        try{
            let res = await fetch(
                `${this.googleApiURL}/v1/accounts:update?key=${this.firebaseAPI_KEY}`,
                {
                    method:"POST",
                    body: JSON.stringify({
                        idToken:token,
                        email: newEmail,
                        returnSecureToken: true
                    }),
                    headers:{
                        "Content-Type": "application/json"
                    }
                }
            );
            let data = await res.json();
            if(!data.error){
                let theUserInfoRes = await fetch(`${this.firebaseRoot}/users/${userId}/userInfo.json?auth=${token}`);
                let theUserInfo = await theUserInfoRes.json();
                if(theUserInfo.error){
                    throw theUserInfo.error;
                }
                console.log("update Email success:", data);
                let promiseArray = [];
                promiseArray.push(
                    fetch(
                        `${this.firebaseRoot}/users/${userId}.json?auth=${token}`,
                        {
                            method:"PATCH",
                            body: JSON.stringify({
                                email: newEmail,
                                userInfo:{
                                    ...theUserInfo,
                                    emailValidateCode:null,
                                    emailVerifyStatus:null
                                }
                            }),
                            headers:{
                                "Content-Type": "application/json"
                            }
                        }
                    )
                );
                promiseArray.push(
                    fetch(
                        `${this.firebaseRoot}/userMeta/${userId}.json?auth=${token}`,
                        {
                            method:"PATCH",
                            body: JSON.stringify({
                                mailSubscription:{
                                    campaignMail:false,
                                    dealPending:false,
                                    systemNotification:false     
                                },
                                verifyMethod : {email: false}
                            }),
                            headers:{
                                "Content-Type": "application/json"
                            }
                        }
                    )
                );
                let [userRes, userMetaRes] = await Promise.all(promiseArray);
                const data2 = userRes.json();
                const data3 = userMetaRes.json();
                if(data2.error || data3.error){
                    if(data2.error){
                        throw data2.error;
                    }
                    if(data3.error){
                        throw data3.error;
                    }   
                } else {
                    return data;
                }
            } else {
                console.log("update Email error");
                throw data.error;    
            }
        } catch (err){
            throw err;
        }   
    }

    async updateOnlineStatus(userId, value, token){
        try {
            let now = moment().format();
            let params = {
                online:value
            }
            if(value){
                params = {
                    ...params,
                    lastLogInDate:now
                }
            }
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchGigs() {
        try {
            let res = await fetch(`${this.firebaseRoot}/gigs.json`);
            let data = await res.json();
            //let normalized_data = this.normalizedData(data);
            return data;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

    async fetchTheGig(id) {
        try {
            let res = await fetch(`${this.firebaseRoot}/gigs/${id}.json`);
            let data = await res.json();
            return data;
        } catch(err) {
            console.log(err)
            throw err;
        }
    }

    async fetchUserMeta(){
        try {
            let res = await fetch(`${this.firebaseRoot}/userMeta.json`);
            let data = res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            throw err;
        }
    }

    async fetchTheUserMeta(userId){
        try {
            console.log(userId);
            let res = await fetch(`${this.firebaseRoot}/userMeta/${userId}.json`);
            let data = res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return fetchTheUserMeta data");
                    return data;
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            throw err;
        }
    }

    async saveMailSubscriptionByKey (userId, key, value, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/mailSubscription.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify({
                        [key]:value
                    })
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            throw err;
        }
    }

    async savePreferenceByKey (userId, key, value, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/preference.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify({
                        [key]:value
                    })
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            throw err;
        }
    }

    async fetchIdCertVerificaiton (certType, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users.json?auth=${token}&orderBy="certificate/${certType}/verifyStatus"&equalTo="PENDING_VERIFICATION"`);
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;
            } else {
                console.log("fetchIdCertVerificaiton error");
                throw new Error(data.error);
            }
        } catch(err){
            throw err;
        }
    }

    async uploadImageToGw( imagePathId, type, userId, token ){
        try {
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeIdToGtw`,
                {
                    method:'POST',
                    body: JSON.stringify({
                        type,
                        imagePathId,
                        userId
                    }),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json" 
                    }
                }
            )
            let data = await res.json();
            if(!data.document){
                throw new Error("image upload failed");
            } else {
                return data.document;    
            }
        } catch (err) {
            throw err;
        }
    }

    async updateVerificationStatus (verifyData, certType, userId, token) {
        try {
            let timeStamp = new Date(2010, 6, 26).getTime() / 1000; // Unix epoch timeStamp
            let newVerifyData = {...verifyData};
            let document = null;
            if(verifyData.verifyStatus === 'NOT_VERIFIED'){
                newVerifyData = {
                    ...newVerifyData,
                    rejected_at: timeStamp,
                    verified_at: null
                }  
            } else {
                if(certType === 'identified'){
                    let response = await Promise.all(verifyData.images.map(async image => {
                        try{
                            const { meta, imagePathId } = image;
                            const docData = await this.uploadImageToGw(imagePathId, meta, userId, token);
                            return docData;
                        } catch (err) {
                            console.log('err', err);
                            throw new Error(err);
                        }
                    }));
                    response.map( docData => {
                        document = {
                            ...document,
                            ...docData
                        }
                        return true;
                    });
                }
                newVerifyData = {
                    ...newVerifyData,
                    rejected_at: null,
                    verified_at: timeStamp,
                }; 
            } 
            let res = await fetch(
                `${this.firebaseRoot}/users/${userId}/certificate/${certType}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(newVerifyData)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;
            } else {
                console.log("fetchIdCertVerificaiton error");
                throw new Error(data.error);
            }
        } catch(err) {
            throw err;
        }
    }

    async fetchSupportCases (token) {
        try {
            let res = await fetch(`${this.firebaseRoot}/supports.json?auth=${token}`);
            let data = await res.json(); 
            console.log("fetchSupportCases success");
            return data; 
        } catch(err) {
            console.log("fetchSupportCases error");
            console.log(err)
            throw err;    
        }   
    }

    async updateAssignee (supportId, userId, status, token) {
        try {
            let newStatus = status === 'NEW' ? 'OPENED' : null;
            let params = {assignee: userId};
            if(newStatus) {
                params = {
                    ...params,
                    status:newStatus 
                }
            }
            let res = await fetch(
                `${this.firebaseRoot}/supports/${supportId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("updateAssignee success")
                return params;
            } else {
                console.log("updateAssignee error");
                throw new Error(data.error);
            }
        } catch(err) { 
            throw err;
        }
    }

    async sendTheSupport (supportData, msg, supportId, token){
        const createdDate = new Date();
        const userIdStr = msg.from.substr(0, 5);
        const newSupportId = supportId ? supportId : `${userIdStr}-${createdDate.getTime()}`;
        let msgId = `${createdDate.getTime()}`;
        let newMsg = {
            ...msg,
            createdDate,
            id:msgId
        }
        let newMsgURL= `${this.firebaseRoot}/supports/${newSupportId}/msg/${msgId}.json?auth=${token}`;
        let fetchURL = `${this.firebaseRoot}/supports/${newSupportId}.json?auth=${token}`;
        
        try {
            let res = await fetch(
                newMsgURL,
                {
                    method:"PUT",
                    body:JSON.stringify(newMsg)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                if(supportData){
                    let res2 = await fetch(
                        fetchURL,
                        {
                            method:"PATCH",
                            body:JSON.stringify(supportData)
                        }
                    );
                    let data2 = await res2.json();
                    if(!data2.error){
                        console.log("send support Update sucess")
                        return { newMsg, supportId, supportData };
                    } else {
                        console.log("send support Update error");
                        throw new Error(data2.error);
                    }
                } else {
                    console.log("send support msg sucess")
                    return newMsg;
                }         
            } else {
                console.log("send support msg error");
                throw new Error(data.error);
            }         
        } catch(err) {
            console.log(err);
            throw err;    
        }  
    };

    async sendNotification(msgData, userId, type, token) {
        try {
            const createdDate = new Date();
            let id = msgData.id 
                ? msgData.id 
                : type==='user' 
                    ? `${userId.substr(0, 5)}-${createdDate.getTime()}`
                    : `${createdDate.getTime()}`;
            let params = {
                ...msgData,
                createdDate,
                id
            };

            const url = type==='user'
                    ? `${this.firebaseRoot}/notifications/${type}/${userId}/${id}.json?auth=${token}`
                    : `${this.firebaseRoot}/notifications/${type}/${id}.json?auth=${token}`
            
            let res = await fetch(
                url,
                {
                    method:"PUT",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("sendNotification success")
                return params;
            } else {
                console.log("sendNotification error");
                throw new Error(data.error);
            }
        } catch(err) { 
            throw err;
        }        
    }

    async fetchNotifications(type, token) {
        try {
            let res = await fetch(`${this.firebaseRoot}/notifications/${type}.json?auth=${token}`);
            let data = await res.json();
            console.log("fetchNotifications success");
            return data; 
        } catch(err) {
            console.log("fetchNotifications error");
            console.log(err)
            throw err;    
        }   
    }

    async sendDeactivateReq(requestData, token) {
        try {
            const { gigId, userId} = requestData;
            const createdDate = new Date();
            const idStr = gigId ?  gigId.substr(0, 5) : userId.substr(0, 5);
            const id = requestData.id ? requestData.id : `${idStr}-${createdDate.getTime()}`;
            let params = {
                ...requestData,
                createdDate,
                id
            };
            let res = await fetch(
                `${this.firebaseRoot}/deactivations/${id}.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("sendDeActivateReq success")
                return params;
            } else {
                console.log("sendDeActivateReq error");
                throw new Error(data.error);
            }
        } catch(err) { 
            throw err;
        }         
    }

    async fetchDeactivations(token) {
        try {
            let res = await fetch(`${this.firebaseRoot}/deactivations.json?auth=${token}`);
            let data = await res.json();
            console.log("fetchDeactivations success"); 
            return data;
        } catch(err) {
            console.log("fetchDeactivations error");
            console.log(err)
            throw err;
        }   
    }

    async deactivateObject(deactivateId, objectId, type, value, token) {
        try {
            const deactivated_at = new Date();
            let params = {
                deactivated_at: value ? deactivated_at : null,
                active:!value
            };
            let objStr = type==='gig' ? 'gigs' : 'userMeta';
            let res = await fetch(
                `${this.firebaseRoot}/${objStr}/${objectId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log(`deactivateObject ${objStr} success`);
                let updateData = {
                    deactivated_at: value ? deactivated_at : null,
                    deactivated:value    
                }
                let res2 = await fetch(
                    `${this.firebaseRoot}/deactivations/${deactivateId}.json?auth=${token}`,
                    {
                        method:"PATCH",
                        body:JSON.stringify(updateData)
                    }
                );
                let data2 = await res2.json();
                if(!data2.error){
                    console.log(`deactivateObject update for ${objStr} success`);
                    return updateData;
                } else {
                    console.log(`deactivateObject update for ${objStr} error`);
                    throw new Error(data2.error);    
                }
            } else {
                console.log(`deactivateObject ${objStr} error`);
                throw new Error(data.error);
            }
        } catch(err) { 
            throw err;
        }   
    }

    async updateAdminLevel(adminData, token) {
        try {
            let res = await fetch(
                `${this.firebaseFunctionURL}/addUsertoAdmin`,
                {
                    method:'POST',
                    body: JSON.stringify(adminData),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            if(data.status === 'error'){
                throw new Error("update AdminLevel Error");
            } else {
                return adminData; 
            }    
        } catch (err) {
            throw err;
        }         
    }

    async fetchNoItemReturnedDeals(token) {
        try {
            const promiseArray = [
                fetch(`${this.firebaseRoot}/messages.json?auth=${token}&orderBy="status"&equalTo="RESALE_NO_RETURN"`),
                fetch(`${this.firebaseRoot}/messages.json?auth=${token}&orderBy="chargeDeposit"&equalTo=true`)
            ]
            const [result1, result2] = await Promise.all(promiseArray);
            let data1 = await result1.json();
            let data2 = await result2.json();
            console.log("fetchNoItemReturnedDeals success"); 
            let data = {...data1, ...data2};
            console.log("data", data); 
            return Object.keys(data).length > 0 ? data : null;
        } catch(err) {
            console.log("fetchNoItemReturnedDeals error");
            console.log(err)
            throw err;
        }       
    }

    async updateAssigneeOfDeal(dealId, userId, token) {
        try {
            let params = {assignee: userId};
            let res = await fetch(
                `${this.firebaseRoot}/messages/${dealId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(params)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("updateAssigneeOfDeal success")
                return params;
            } else {
                console.log("updateAssigneeOfDeal error");
                throw new Error(data.error);
            }
        } catch(err) { 
            throw err;
        }
    }

    async fetchTheMessage(dealId, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/messages/${dealId}.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    if(Object.keys(data).length === 0){
                        data = null;
                    }
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    processOrderData = (createdDate, theMessage, orderId, orderData) => {
        let newOrderData = theMessage && theMessage.orders ? {...theMessage.orders} : {};
        if(orderData){
            newOrderData = {
                ...newOrderData,
                [orderId] : {
                    id : orderId,
                    ...orderData,
                    createdDate
                }
            }
        }    
        if(Object.keys(newOrderData).length === 0){
            newOrderData = null
        }
        return newOrderData;
    }

    async sendOrderMessage( messageData, dealId, token, orderData, status, activeOrderId, chargeDeposit){
        try {
            const {gigId, customerId, providerId} = messageData;
            let now = moment().format();
            let theMessage = await this.fetchTheMessage(dealId, token);

            let newMsg = {
                ...messageData,
                createdDate: now    
            };
            
            let res = await fetch(
                `${this.firebaseRoot}/messages/${dealId}/msg.json?auth=${token}`,
                {
                    method:"POST",
                    body:JSON.stringify(newMsg)
                }
            );
            let data = await res.json(); 
            if(data.error){
                console.log("msg post error");
                throw new Error(data.error);    
            } else {
                let newMessageData = {
                    dealId,
                    gigId, 
                    customerId,
                    providerId
                }
                if(activeOrderId){
                    newMessageData = {
                        ...newMessageData,
                        activeOrderId,
                        status: status,
                        orders: this.processOrderData(now, theMessage, activeOrderId, orderData),
                        chargeDeposit: chargeDeposit ? chargeDeposit : null
                    }     
                }
                if(status === 'PAYMENT_COMPLETED'){
                    newMessageData = {
                        ...newMessageData,
                        closed_at:now    
                    }    
                }
                let resDeal = await fetch(
                    `${this.firebaseRoot}/messages/${dealId}.json?auth=${token}`,
                    {
                        method:"PATCH",
                        body:JSON.stringify(newMessageData)
                    }
                );
                let dataDeal = await resDeal.json();
                if(dataDeal.error){ 
                    console.log("message(deal) patch error");
                    throw new Error(dataDeal.error);      
                } else {
                    let newMessage = {
                        ...theMessage,
                        ...dataDeal,
                        msg:{
                            ...theMessage.msg,
                            [data.name]: newMsg
                        }
                    }
                    return newMessage;
                }    
            }    
        } catch(err) { 
            //console.log(err)
            throw err;
        }    
    }

    async fetchAllSales(token){
        try {
            let res = await fetch(`${this.firebaseRoot}/sales.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data");
                    return data;       
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveSale (saveData, mode, token){
        let userId = mode === 'provider' ? saveData.providerId : saveData.customerId;
        try {
            let res = await fetch(
                `${this.firebaseRoot}/sales/${mode}/${userId}/${saveData.id}.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(saveData)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("save Sale error");
                throw new Error(data.error);
            } 
        } catch(err) {
            console.log(err);
            throw err;    
        }  
    }

    async saveTheSale(chargeData, theOrder, dealId, providerId, customerId, gigId, token){
        try {
            let id = chargeData.charge.id;
            let cancel = theOrder.cancelFee ? true : false;
            let commonData = {
                id,
                dealId,
                providerId,
                customerId,
                gigId,
                theOrder,
                cancel,
                charge: chargeData.charge,
                transferMoneyStatus:"NOT_TRANSFERED",   
            };
            let customerData = {
                ...commonData,
                card: chargeData.card
            }
            let providerData = {
                ...commonData,
                transferable_status: chargeData.transferable_status,
                available_on: chargeData.available_on,
                charge: {
                    ...commonData.charge,
                    handlingFee: chargeData.handlingFee,
                    profit: chargeData.profit
                }
            }
            // eslint-disable-next-line no-unused-vars
            const result = await Promise.all([this.saveSale(providerData, 'provider', token), this.saveSale(customerData, 'customer', token)]);
            return {
                provider: { ...providerData },
                customer: { ...customerData }
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchTheUser(userId, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${userId}.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data return case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async chargeServiceFee(chargeData, providerId, taguJam_customerId, localCode, token){
        try {
            let currency = this.getCurrentyUnit(localCode);
            let forVerify = false;
            let newChargeData = {...chargeData, currency, providerId, taguJam_customerId, forVerify};
            let res = await fetch(
                `${this.firebaseFunctionURL}/createCharge/`,
                {
                    method:'POST',
                    body: JSON.stringify(newChargeData),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            console.log(data);
            if(data.status==='succeeded'){
                console.log("STRIPE result:", data);
                return data;     
            } else {
                if(data.failure_code)
                {
                    throw new Error({name:data.failure_code, message:data.failure_message });
                } else {
                    throw new Error("charge failed with unknown reason!");
                }  
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async updateChargeVerify (chargeId, customerId, providerId, charge, mode, token){
        try {
            let userId = mode === 'provider' ? providerId : customerId; 
            let chargeObj = {
                providerId,
                customerId,
                charge: charge
            };
            let res = await fetch(
                `${this.firebaseRoot}/chargeVerify/${mode}/${userId}/${chargeId}.json?auth=${token}`,
                {
                    method:"PATCH",
                    body:JSON.stringify(chargeObj)
                }
            );
            let data = await res.json(); 
            if(data && !data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("update ChargeVerify update error");
                let error=null;
                if(data && data.error){
                    error = data.error;
                } else {
                    error= "update ChargeVerify update error";   
                }
                throw new Error(error);
            } 
        } catch(err) {
            console.log(err);
            throw err;    
        }  
    }

    async updateTheCharge(chargeId, chargeData, customerId, providerId, token){
        try {
            const charge = chargeData.charge;
            // eslint-disable-next-line no-unused-vars
            const result = await Promise.all([
                this.updateChargeVerify(chargeId, customerId, providerId, charge, 'provider', token), 
                this.updateChargeVerify(chargeId, customerId, providerId, charge, 'customer', token)
            ]);
            return charge;
        } catch(err){
            console.log(err)
            throw err;
        }
    };

    async reverseCharge(chargeId, token){
        const chargeIdObj = {chargeId:chargeId};
        try {
            let res = await fetch(
                `${this.firebaseFunctionURL}/reverseCharge`,
                {
                    method:'POST',
                    body: JSON.stringify(chargeIdObj),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            console.log(data);
            if(data.status==='succeeded'){
                console.log("STRIPE result:", data);
                return data;     
            } else {
                if(data && data.failure_reason)
                {
                    throw new Error({err:true, name:data.failure_reason, message:"charge refund filed with a reason" });
                } else {
                    throw new Error({err:true, name:"CHARGE REVERSE FAILED", message:"charge refund filed with unknown reason!" });
                }  
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchAccountInfo(userId){
        try {
            let res = await fetch(`${this.firebaseRoot}/userMeta/${userId}/accountInfo.json`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async uploadImage(image, destination, imagename, token){
        try {
            let imageData = {
              destination,
              imagename,
              image: image.base64
            };
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeImage`, {
                    method:'POST',
                    body: JSON.stringify(imageData),
                    headers: {
                      "Authorization": `Bearer ${token}`,
                      'Content-Type': 'application/json'
                    }
                });
            let data = await res.json();
            if(!data.imageUrl){
                throw new Error("image upload failed");
            } else {
                return data.imageUrl;    
            }
        } catch (err) {
            throw err;
        }
    }

    async saveAccountInfo(accountInfoData, userId, token){
        try {
            const { displayName, selfPR, avatorImage } = accountInfoData;
            const destination = `/avators/${userId}/`;
            const imagename = 'avator';
            let imageUrl;
            if(avatorImage.base64){
                imageUrl = await this.uploadImage(avatorImage, destination, imagename, token);
            } else {
                imageUrl = avatorImage.avator;
            }
            let res = await fetch(
                `${this.firebaseRoot}/userMeta/${userId}/accountInfo.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify({
                        displayName: displayName,
                        selfPR: selfPR,
                        avator: imageUrl
                    })
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            console.log("catch block due to error");
            throw err;    
        }
    }

    async fetchUserInfo(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/userInfo.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchUserBankMeta(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/bank.json?auth=${token}`);
            let data = await res.json();
            console.log("res", res);
            if(data){
                if(!data.error){
                    return data;
                } else {
                    console.log("fetch User BankMeta error");
                    throw new Error("fetch User BankMeta");    
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async saveRecipient( accountData, userId, recipientId, token ){
        try {
            const saveData = {
                userId: userId,
                recipientId: recipientId,
                accountData
            };
           
            let res = await fetch(
                `${this.firebaseFunctionURL}/storeRecipient`,//`${this.taguJamApiURL}/recipients`,
                {
                    method:'POST',
                    body: JSON.stringify(saveData),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            console.log(data);
            if(!data.recipientId){
                throw new Error("recipient creation failed");
            } else {
                return data;    
            }
        } catch (err) {
            throw err;
        }
    }

    async saveUserInfo(userInfoData, id, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(userInfoData)
                }
            );
            let data = await res.json();
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }   
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveVerifyStatus(id, verifyStatus, token){
        try {
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo/emailVerifyStatus.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(verifyStatus)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return verifyStatus;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }       
        } catch (err) {
            throw err;
        }    
    }

    async sendEmail(email, mode, token) {
        try {
            let functionName = (mode==='verifyEmail') ? 'sendEmailValification' : 'sendOrderComfirmation';
            let res = await fetch(
                `${this.firebaseFunctionURL}/${functionName}`,
                {
                    method:'POST',
                    body: JSON.stringify({
                        email: email
                    }),
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            )
            let data = await res.json();
            if(!data.emailCode){
                throw new Error("sending verification code failed");
            } else {
                return data.emailCode; 
            }    
        } catch (err) {
            throw err;
        }
    } 

    async sendEmailVerifyCode(id, email, token){
        try {
            let mode = 'verifyEmail';
            let emailCode = await this.sendEmail(email, mode, token);
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/userInfo/emailValidateCode.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(emailCode)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return emailCode;        
            } else {
                console.log("storing verification error");
                throw new Error(data.error);
            }       
        } catch (err) {
            throw err;
        }
    }

    async fetchBank(id, token){
        try {
            let res = await fetch(`${this.firebaseRoot}/users/${id}/bank.json?auth=${token}`);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data");
                    let bankInfo = null;
                    if(data.recipientId){
                        let bankRes = await fetch(
                            `${this.firebaseFunctionURL}/getRecipient/?recipientId=${data.recipientId}`,//`${this.taguJamApiURL}/recipients/${data.recipientId}`,
                            {
                                method:'GET',
                                headers: {
                                    "Authorization": `Bearer ${token}`
                                }
                            }
                        )
                        bankInfo = await bankRes.json();
                    }
                    if(data.document){
                        bankInfo = {
                            ...bankInfo,
                            document:data.document
                        }
                    }
                    return bankInfo;       
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async fetchUserAddress(id, token, type){
        try {
            let url = type ? `${this.firebaseRoot}/users/${id}/address/${type.toLowerCase()}.json?auth=${token}` : `${this.firebaseRoot}/users/${id}/address.json?auth=${token}`;
            let res = await fetch(url);
            let data = await res.json();
            if(data){
                if(!data.error){
                    console.log("no error let's return data")
                    return data;        
                } else {
                    console.log("data fetch error");
                    throw new Error(data.error);
                }
            } else {
                console.log("empty data case");
                return data;    
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async saveUserAddress(addressData, id, token){
        try {
            let type = addressData.addressType.toLowerCase();
            let res = await fetch(
                `${this.firebaseRoot}/users/${id}/address/${type}.json?auth=${token}`,
                {
                    method:"PUT",
                    body:JSON.stringify(addressData)
                }
            );
            let data = await res.json(); 
            if(!data.error){
                console.log("no error let's return data")
                return data;        
            } else {
                console.log("data save error");
                throw new Error(data.error);
            }
        } catch(err){
            //console.log(err)
            throw err;
        }
    }

    async deleteUserAddress(type, id, token){
        try {
            let result = await fetch(
                `${this.firebaseRoot}/users/${id}/address/${type}.json?auth=${token}`,
                {
                    method:"DELETE"
                }
            );
            //let result = await res.json(); 
            console.log("result", result);
            if(result.ok){
                return result;
            } else {
                console.log("data delete error");
                throw new Error("Data delete error");    
            }
        } catch(err){
            console.log(err)
            throw err;
        }
    }

    async fetchBlog() {
      try {
          let res = await fetch(`${this.firebaseRoot}/blog.json`);
          let data = await res.json();
          return data;
      } catch(err) {
          console.log(err)
          throw err;
      }
    }

    async saveTheArticle(articleData, token){
      try {
          const { 
            id,
            title,
            articleImage,
            coverImage,
            shortDescription,
            body,
            MainCat,
            SubCat,
            createdDate
          } = articleData;
          let blogId = id ? id : `${new Date().getTime()}`;
          const destination = `/blog/articles/${blogId}/`;
          const articlesImagename = 'article';
          const coverImagename = 'cover';
          let promiseArray = [];
          if(articleImage.base64){
            promiseArray.push(this.uploadImage(articleImage, destination, articlesImagename, token));
          } else {
            promiseArray.push(articleImage.imagePath);
          }
          if(coverImage.base64){
            promiseArray.push(this.uploadImage(coverImage, destination, coverImagename, token));
          } else {
            promiseArray.push(coverImage.imagePath);
          }

          const [imagePath, coverImagePath] = await Promise.all(promiseArray);

          let res = await fetch(
              `${this.firebaseRoot}/blog/articles/${blogId}.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify({
                  id: blogId,
                  title,
                  imagePath,
                  coverImagePath,
                  shortDescription,
                  body,
                  MainCat,
                  SubCat,
                  createdDate : createdDate ? createdDate : new Date(),
                  modifiedDate : createdDate ? new Date() : null  
                })
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }

    async saveTheVideo(videoData, token){
      try {
          const { 
            id,
            title,
            imagePath,
            shortDescription,
            howToVideo,
            createdDate
          } = videoData;
          let videoId = id ? id : `${new Date().getTime()}`;
          let res = await fetch(
              `${this.firebaseRoot}/blog/videos/${videoId}.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify({
                  id: videoId,
                  title,
                  imagePath,
                  shortDescription,
                  howToVideo,
                  createdDate : createdDate ? createdDate : new Date(),
                  modifiedDate : createdDate ? new Date() : null  
                })
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }

    async saveHowToStart(howToStartData, token){
      try {
          let res = await fetch(
              `${this.firebaseRoot}/blog/howToStart.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify(howToStartData)
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }

    async saveExamples(examplesData, token){
      try {
          let res = await fetch(
              `${this.firebaseRoot}/blog/examples.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify(examplesData)
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }

    async saveFeatures(featuresData, token){
      try {
          let res = await fetch(
              `${this.firebaseRoot}/blog/features.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify(featuresData)
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }

    async saveBlogHome(blogHomeData, token){
      try {
          let res = await fetch(
              `${this.firebaseRoot}/blog/blogHome.json?auth=${token}`,
              {
                method:"PUT",
                body:JSON.stringify(blogHomeData)
              }
          );
          let data = await res.json(); 
          if(!data.error){
              console.log("no error let's return data")
              return data;        
          } else {
              console.log("data save error");
              throw new Error(data.error);
          }
      } catch(err){
          //console.log(err)
          console.log("catch block due to error");
          throw err;    
      }
    }
        
};
