import React from 'react';
import styles from './FormInputStatic.module.scss';

const FormInputStatic = (props) => { 
  const { 
      label, 
      placeholder, 
      customStyle,
      customInputStyle,
      value, 
      error, 
      valid, 
      touched,
      clicked
  } = props;
  let inputStyles = [styles.InputBox];
  if(error && touched){
      inputStyles.push(styles.ErrorItem);
  }
  if(valid){
      inputStyles.push(styles.ValidItem);    
  }
  return(
    <div onClick={clicked} className={styles.FormInput} style={customStyle ? customStyle : null}>
        {label &&
          <label className={styles.Label}>
            {label}
          </label>
        }
        <div style={{width:'100%', display:'flex', justifyContent:'flex-end', alignItems: "center"}}>
          <p className={inputStyles.join(' ')} style={customInputStyle ? customInputStyle : null}> 
            {value ? value : placeholder}
          </p>
        </div>
        { (error && touched) &&
        <div className={styles.Error}>
        {error}
        </div>
        }
    </div>
  )
}

export default FormInputStatic;