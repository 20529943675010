const AddLocalStrings = {
    "ja_jp": {
        "TAGUJAM_ADMIN_TOOL":"タグジャム　管理ツール",
        "ADMIN_MENU":"管理者メニュー",
        "ADMIN_HOME":"管理者ホーム",
        "ADMIN_INFO_HOME":"管理者情報ホーム",
        "TAGUJAM_ADMIN":"タグジャム管理者",
        "ADMIN_ITEMS":"管理者目次",
        "ID_CERT_VERIFY":"本人確認・証明書承認",
        "CUSTOMER_SUPPORT":"カスタマーサポート",
        "DEACTIVATE":"サービス・アカウントの無効化",
        "RENT_ITEM_RESALE":"レンタル品の転売処理",
        "BLOG_ADMIN":"ブログの管理",
        "NOTIFICATIONS":"通知の管理",
        "MANAGE_ADMIN_GROUP":"管理者グループ編集",
        "PLEASE_AGREE": "同意願います。",
        "VERIFY_RESULT":"承認結果",
        "CERT": "証明証",
        "ACCEPT": "承認",
        "TO_REPOTER":"報告者への",
        "OF_OBJECT":"の対象",
        "SELECT":"選択",
        "NOT_CLEAR_IMAGE" : "画像が不鮮明",
        "NAME_IS_DIFFERENT":"氏名が一致しません",
        "ADDRESS_IS_DIFFERNT": "住所が一致しません",
        "BIRTHDAY_IS_DIFFERNT" : "生年月日が一致しません",
        "EXPIRED": "有効期限切れです",
        "NOT_ID": "身分証明に該当しません",  
        "NOT_STAMPED": "受付印がありません",  
        "NOT_GOV_REGISTRED": "届出書に該当しません",  
        "NOT_HEALTH_DEPT_DOC": "保健所届出書に該当しません",
        "NOT_NURCE_CERT":"看護師免許証に該当しません",
        "NOT_ANIMAL_CERT":"動物取扱い資格に該当しません",
        "NOT_ANIMAL_HANDLING":"第一種動物取扱業登録者証に該当しません",
        "NOT_HAIRDRESS_CERT":"理容・美容師免許証に該当しません",  
        "NOT_CHILD_CARE_CERT":"保育免許証に該当しません",  
        "NOT_CARE_WAKER_CERT":"介護士許証に該当しません",  
        "NOT_CERT":"証明証に該当しません",
        "NOT_PRO":"プロ経験の証明に該当しません",
        "TAGUJAM_CUSTOMER_SUPPORT": "タグジャムサポート",
        "TAGUJAM": "タグジャム",
        "TYPE_FACE":"タイプフェイス",
        "LOGO":"ロゴ",
        "MESSAGE": "メッセージ",
        "PHOTO": "写真",
        "SEND_NOTIFICATION_MESSAGE": "通知メッセージ送信",
        "PEND_ACCOUNT_DEACTIVATE": "アカウント無効化申請",
        "PEND_SERVICE_DEACTIVATE": "サービス無効化申請",
        "SELECT_PREPED_SENTENCE":"定形文",
        "INPUT_NOTIFICATION_MESSAGE": "通知メッセージを入力して下さい。",
        "ASSIGNEE":"担当者",
        "NO_ASSIGNEE":"担当者なし",
        "ASSIGNEE_CHANGE": "担当者変更", 
        "ASSIGNEE_SELECT": "担当者選択",
        "USER_CHANGE":"ユーザー変更",
        "USER_SELECT":"ユーザー選択",
        "STATUS_CHANGE" : "ステイタス変更",
        "NO_SUPPORTCASE_FOUND": "サポートケースはありません。",
        "DATA_LOADING_ERROR": "読み込みエラー",
        "SEND_NOTIFICATION": "通知を送信する",
        "SEND_DEACTIVATE_REQUEST": "無効化をリクエスする",
        "ASKED_PERSONAL_INFOMATION": "個人情報を聞き出そうとした。",
        "ASKED_PERSONAL_INFOMATION_TEXT": "個人情報を聞き出そうとした。",
        "PUBLISHED_PERSONAL_INFOMATION":"個人情報を勝手に公開した。",
        "PUBLISHED_PERSONAL_INFOMATION_TEXT":"個人情報を勝手に公開した。",
        "SLANDER": "誹謗中傷行為を行った。",
        "SLANDER_TEXT": "誹謗中傷行為を行った。",
        "BLACKMAIL":"脅迫行為を行った。",
        "BLACKMAIL_TEXT":"脅迫行為を行った。",
        "DESCRIMINATION":"差別行為を行った。",
        "DESCRIMINATION_TEXT":"差別行為を行った。",
        "GIVE_DISCONFORT":"不快感を与えた。",
        "GIVE_DISCONFORT_TEXT":"不快感を与えた。",
        "DAMAGE_OTHERS":"不利益・損害を与えた。",
        "DAMAGE_OTHERS_TEXT":"不利益・損害を与えた。",
        "SEXUAL_REMARKS":"性的発言を行った。",
        "SEXUAL_REMARKS_TEXT":"性的発言を行った。",
        "RELIGIOUS_ACTIVITY":"違法行為を行った。",
        "RELIGIOUS_ACTIVITY_TEXT":"違法行為を行った。",
        "ELECTION_CAMPAIGN":"選挙運動を行った。",
        "ELECTION_CAMPAIGN_TEXT":"選挙運動を行った。",
        "OWN_MULTIPLE_ACCOUNTS":"複数のアカウントを所有した",
        "OWN_MULTIPLE_ACCOUNTS_TEXT":"複数のアカウントを所有した",
        "MULTI_LAYERED_MARKETING":"マルチ商法を行った。",
        "MULTI_LAYERED_MARKETING_TEXT":"マルチ商法を行った。",
        "OTHER_SITE_MARKETING":"別のサイトの勧誘活動をした。",
        "OTHER_SITE_MARKETING_TEXT":"別のサイトの勧誘活動をした。",
        "BUSINESS_INTERRUPTION":"営業の妨害をした。",
        "BUSINESS_INTERRUPTION_TEXT":"営業の妨害をした。",
        "INFRINGEMENT_PATENT_RIGHT":"著作物の勝手な使用をした。",
        "INFRINGEMENT_PATENT_RIGHT_TEXT":"著作物の勝手な使用をした。",
        "NO_INTENTION_SELL":"サービスを販売する気がない物を出品。",
        "NO_INTENTION_SELL_TEXT":"サービスを販売する気がない物を出品。",
        "OFFENDING_CONTENT":"猥褻なコンテンツ。",
        "OFFENDING_CONTENT_TEXT":"猥褻なコンテンツ。",
        "MISLEADING_CONTENT":"誤解を与えるコンテスツ。",
        "MISLEADING_CONTENT_TEXT":"誤解を与えるコンテスツ。",
        "DUPLICATED_SERVICE":"重複したサービス。",
        "DUPLICATED_SERVICE_TEXT":"重複したサービス。",
        "BEHALF_SERVICE_POSTING":"代行出品した。",
        "BEHALF_SERVICE_POSTING_TEXT":"代行出品した。",
        "USE_OTHERS_CONTENT":"他人のコンテンツを無断使用した。",
        "USE_OTHERS_CONTENT_TEXT":"他人のコンテンツを無断使用した。",
        "ILLEGAL_ACTIVITY":"違法行為を行った。",
        "ILLEGAL_ACTIVITY_TEXT":"違法行為を行った。",
        "POST_CONTACT_INFO":"自分の連絡先を投稿した。",
        "POST_CONTACT_INFO_TEXT":"自分の連絡先を投稿した。",
        "ASK_DEAL_OUTSIDE_SYSTEM":"外で取引を促した。",
        "ASK_DEAL_OUTSIDE_SYSTEM_TEXT":"外で取引を促した。",
        "USE_NONE_TAGUJAM_PAYMENT":"タグジャム意外の決済方法を進めた。",
        "USE_NONE_TAGUJAM_PAYMENT_TEXT":"タグジャム意外の決済方法を進めた。",
        "MONEY_LAUNDERING":"マネーロンダリングをした。",
        "MONEY_LAUNDERING_TEXT":"マネーロンダリングをした。",
        "BUY_SERVICE_HIMSELF":"自分自身でサービスを購入した。",
        "BUY_SERVICE_HIMSELF_TEXT":"自分自身でサービスを購入した。",
        "SERVICE_NOT_FULFILLED":"サービスが不履行",
        "SERVICE_NOT_FULFILLED_TEXT":"サービスが不履行",
        "UNREASONABLE_REQUESTE":"不当な要求をした。",
        "UNREASONABLE_REQUESTE_TEXT":"不当な要求をした。",
        "INPUT_DEACTIVATE_REQUEST":"無効化を申請する理由を入力して下さい。",
        "SELECT_DEACTIVATE_REASON":"無効化の理由選択",
        "STATUS":"ステイタス",
        "PENDING_ACCOUNT_DEACTIVATE": "アカウント無効化申請中",
        "PENDING_SERVICE_DEACTIVATE": "サービス無効化申請中",
        "DEACTIVATED": "無効化済み",
        "REVERT":"解除",
        "WARNING":"警告",
        "BANNED_BEHAVIOR_WARNING": "禁止行為の注意警告",
        "BANNED_BEHAVIOR_WARNING_TEXT":"○○の行為はタグジャムでは固く禁止されています。禁止行為をやめない場合は、アカウントの停止・無効化を施す可能性がありますので気をつけてください。",
        "BANNED_SERVICE_ITEM_WARNING": "禁止物の注意警告",
        "BANNED_SERVICE_ITEM_WARNING_TEXT": "○○の出品はタグジャムでは固く禁止されています。サービスの停止・無効化を施す可能性がありますので気をつけてください。",
        "BANNED_SLANDER_WARNING": "誹謗中傷禁止の注意警告",
        "BANNED_SLANDER_WARNING_TEXT": "誹謗中傷の行為はタグジャムでは固く禁止されています。禁止行為をやめない場合は、アカウントの停止・無効化を施す可能性がありますので気をつけてください。",
        "UNREASONABLE_REQUESTE_WARNING": "常識の範囲外の要求への注意警告",
        "UNREASONABLE_REQUESTE_WARNING_TEXT": "サービス提供者・購入者に過剰な要求はタグジャムでは固く禁止されています。禁止行為をやめない場合は、アカウントの停止・無効化を施す可能性がありますので気をつけてください。",
        "NO_RETURN_RENTAL_ITEM": "レンタル品無返却",
        "NO_RETURN_RENTAL_ITEM_TEXT": "返却要請にもかかわらず、レンタル品が返却されなかった為",
        'PAYMENT_REQUEST':"支払い要請",
        'PAYMENT_REQUEST_TEXT':"○○のお支払いが完了していないようです。速やかにお支払い願います。",
        'PURCHASE_PENDING':"購入申請",
        'RENT_ITEM_RETURN_REQUEST':"レンタル品返却のお願い",
        'RENT_ITEM_RETURN_REQUEST_TEXT':"レンタル品の返却が完了されていないようです。速やかにサービス提供者に返却してください。なお保証金にて買い取り希望の場合はサービス提供者に連絡し、レンタル品の売却の請求書を送信してもらってください。",
        'RENT_ITEM_DEPOSIT_WILL_PROCESS':"保証金が自動的に請求・領収注意警告",
        'RENT_ITEM_DEPOSIT_WILL_PROCESS_TEXT':"貸主からレンタル品の返却が要請されています。\nDUEDATEまでにタグジャムシステムがレンタル品の返却を確認できない場合は、保証金が自動的に請求・領収されますので、速やかにレンタル品を返却してください。",
        'GENERAL':"タグジャムからのお願い",
        'EMAIL_CONFIRMATION_REQUEST':"メルアドを確認のお願い",
        'EMAIL_CONFIRMATION_REQUEST_TEXT':"メルアドを確認がお済みでないようです。アカウント　> ユーザー情報にて確認願います。",
        'BANK_REGISTRATION_REQUEST':"銀行登録のお願い",
        'BANK_REGISTRATION_REQUEST_TEXT':"売上金の銀行登録がお済みでないようです。アカウント　> 銀行登録にて登録願います。",
        'ADDRESS_REGISTRATION_REQUEST':"住所登録のお願い",
        'ADDRESS_REGISTRATION_REQUEST_TEXT':"住所の登録がお済みでないようです。アカウント　> 住所にて登録願います。",
        'PAYMENT_REGISTRATION_REQUEST':"支払い情報登録のお願い",
        'PAYMENT_REGISTRATION_REQUEST_TEXT':"支払い情報の登録がお済みでないようです。アカウント　> 支払い情報にて登録願います。",
        'USER_INFO_REGISTRATION_REQUEST':"ユーザー情報の登録のお願い",
        'USER_INFO_REGISTRATION_REQUEST_TEXT':"ユーザー情報の登録がお済みでないようです。アカウント　> ユーザー情報にて登録願います。",
        "SYSTEM_NOTICE": "システムからのお知らせ",
        "GENERAL_INFO":"タグジャム情報",
        "GENERAL_INFO_TEXT":"タグジャムでは○○を開始いたしました。詳しくはウェブページをご覧ください。",
        "CAMPAING_INFO":"キャンペーン情報",
        "CAMPAING_INFO_TEXT":"タグジャムで○月○日午前○時○分ー午前○時○分○○キャンペーンを行います。詳しくはウェブページにてキャンペーン情報をご覧ください。",
        "SYSTEM_MAINTANANCE":"システムメンテナンスのお知らせ",
        "SYSTEM_MAINTANANCE_TEXT":"システムメンテナンスのお知らせ。○月○日午前○時○分ー午前○時○分 メンテナンスのためシステムが使えなくなります。",
        'NOTICE_TYPE':"通知の種類",
        "DEACTIVATE_ACCOUNT_REVERT": "アカウントを無効化解除",
        "DEACTIVATE_ACCOUNT_REVERT_TEXT": "タグジャム事務局が無効化が必要ないと判断しましたのでアカウントを無効化を解除いたしました。",
        "DEACTIVATE_SERVICE_REVERT": "サービスを無効化解除",
        "DEACTIVATE_SERVICE_REVERT_TEXT": "タグジャム事務局が無効化が必要ないと判断しましたのでサービスを無効化を解除いたしました。",
        "DEACTIVATE_ACCOUNT": "アカウントを無効化",
        "DEACTIVATE_ACCOUNT_TEXT": "タグジャム事務局が違反行為を発見・又は通報を受け違反行為の改善が確認できませんでしたので、アカウントを無効化いたしました。",
        "DEACTIVATE_SERVICE": "サービスを無効化",
        "DEACTIVATE_SERVICE_TEXT": "タグジャム事務局が違反した出品サービスを発見・又は通報を受け違反出品サービスの改善が確認できませんでしたので、サービスを無効化いたしました。",
        "CHAT_BUBBLE_LEFT":"チャット吹き出し左",
        "CHAT_BUBBLE_RIGHT":"チャット吹き出し右",
        "READ":"既読",
        "ADMIN_GENERAL":"一般管理者",
        "ADMIN_MASTER":"特別管理者",
        "NO_RETURN_ITEM_PROCESS":"未返却の処理",
        "CHARGE_DEPOSIT":"保証金を請求する",
        "CHARGE_AMOUNT":"保証金を課金する",
        "DEPOSIT_DETAILS":"保証金明細",
        "RESALE_FEE_PAYBY_DEPOSIT_DESCRIPTION":"レンタル品の転売代金が保証金を領収により完了しました。",
        "RECEIPT_DATE":"領収日",
        "IMAGE_KIND_SELECT": "画像種類選択",
        "FRONT":"表",
        "BACK":"裏",
        "IMAGE_TYPE_SAME":"画像の種類がどちらも同じです",
        "ARTICLES":"記事",
        "COVER":"カバー",
        "BLOGHOME":"注目の記事",
        "HOWTOSTART":"始めの一歩",
        "EXAMPLES":"活用例",
        "FEATURES":"便利機能",
        "HOWTOVIDEOS":"使い方動画",
        "VIDEOS":"動画",
        "ARTICLE_IMAGE":"記事の画像",
        "COVER_IMAGE":"記事のカバー画像",
        "ARTICLE_TITLE":"記事のタイトル",
        "ARTICLE_TITLE_INPUT": "記事のタイトルを入力",
        "SHORT_DESCRIPTION":"記事の詳細(短)",
        "SHORT_DESCRIPTION_INPUT":"記事の詳細(短)を入力",
        "ARTICLE_DESCRIPTION":"記事の詳細(長)",
        "ARTICLE_DESCRIPTION_INPUT":"記事の詳細(長)を入力",
        "ARTICLE_MOVIE_SELECT":"記事の動画選択",
        "ARTICLE_MOVIE_ID":"記事の動画ID",
        "HOWTO_MOVIE_SELECT":"使い方動画選択",
        "HOWTO_MOVIE_ID":"使い方動画ID",
        "RELATED_ARTICLES_SELECT":"関連記事選択",
        "RELATED_ARTICLES": "関連記事",
        "RELATED_ARTICLES_IDS": "関連記事IDs",
        "MAINCAT":"メイン項目",
        "SUBCAT":"サブ項目",
        "ARTICLE_LIST":"記事の一覧",
        "VIDEO_LIST":"動画の一覧",
        "NO_ARTICLES":"記事がありません",
        "NO_VIDEOS":"動画がありません",
        "SELECT_ARTICLES":"この記事を選ぶ",
        "VIDEO_URL":"動画のURL",
        "VIDEO_URL_INPUT":"動画のURLを入力",
        "VIDEO_TITLE":"動画のタイトル",
        "VIDEO_TITLE_INPUT": "動画のタイトルを入力",
        "SHORT_VIDEO_DESCRIPTION":"動画の詳細(短)",
        "SHORT_VIDEO_DESCRIPTION_INPUT":"動画の詳細(短)を入力",
        "INCLUDE_VIDEO_TO_HOWTOMOVIES":"この動画を使い方動画ページに含む",
        "RELEASE_SELECTION":"選択解除",
        "MAIN_ARTICLE":"注目の記事メイン",
        "SUB_ARTICLES":"注目の記事サブ",
        "PAGE":"ページ",
        "ADD_PAGE":"ページの追加",
        "DELETE_PAGE":"ページの削除"
      }
}

export default AddLocalStrings;