import React from 'react';
import styles from './TextLink.module.scss';

const TextLink = (props) => {
    const { children, href, target, style, onClick } = props;
    return(
        <a 
            className={styles.TextLink}
            href={href}
            target={target}
            style={style}
            onClick={onClick}
        >
            {children}
        </a>
    )
}

export default TextLink;