import {
    SET_DEACTIVATE,
    FETCH_DEACTIVATIONS,
    SET_THE_DEACTIVATE
} from '../actions/actionTypes';

const initState = {
    deactivations: null,
    deactivationsIsLoading: false,
    deactivationsError: null
};

const deactivationReducer = ( state=initState, action ) => {
    const { type, payload } = action;
    switch( type ) {
        case SET_DEACTIVATE:{
            return {
                ...state,
                deactivations: {
                    [payload.id]: payload
                } 
            };
        }
        case SET_THE_DEACTIVATE:{
            return {
                ...state,
                deactivations: {
                    ...state.deactivations,
                    [payload.id]: {
                        ...state.deactivations[payload.id],
                        ...payload.updateDeactive    
                    }
                } 
            };    
        }
        case `${FETCH_DEACTIVATIONS}_PENDING`:{
            return {
                ...state,
                deactivationsIsLoading: true,
                deactivationsError: false
            };
        }
        case `${FETCH_DEACTIVATIONS}_FULFILLED`:{
            return {
                ...state,
                deactivations: payload,
                deactivationsIsLoading: false,
                deactivationsError: false
            };
        }
        case `${FETCH_DEACTIVATIONS}_REJECTED`:{
            return {
                ...state,
                deactivationsIsLoading: false,
                deactivationsError: payload
            };
        }
        default:
            return state;
    }
};

export default deactivationReducer;
